import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Lock Simple SVG
 *
 * @returns
 */
function LockSimpleSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 28 32" className={classnames('roc-icon roc-icon-lock-simple', className)} {...rest}>
			<path d="M23.795 13.265h-1.368v-4.103c0-4.513-3.692-8.205-8.205-8.205s-8.205 3.692-8.205 8.205v4.103h-1.368c-2.325 0-4.103 1.778-4.103 4.103v9.573c0 2.325 1.778 4.103 4.103 4.103h19.145c2.325 0 4.103-1.778 4.103-4.103v-9.573c0-2.325-1.778-4.103-4.103-4.103zM8.752 9.162c0-3.009 2.462-5.47 5.47-5.47s5.47 2.462 5.47 5.47v4.103h-10.94v-4.103zM25.162 26.94c0 0.82-0.547 1.368-1.368 1.368h-19.145c-0.821 0-1.368-0.547-1.368-1.368v-9.573c0-0.82 0.547-1.368 1.368-1.368h19.145c0.82 0 1.368 0.547 1.368 1.368v9.573z" />
		</svg>
	);
}

export default LockSimpleSVG;
