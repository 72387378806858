import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Circle Exclaimation SVG component
 *
 * @returns
 */
function CircleExclamationSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('roc-icon roc-icon-circle-exclamation', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16c-8.837 0-16 7.163-16 16 0 4.243 1.686 8.313 4.686 11.314s7.070 4.686 11.314 4.686zM14.16 8.464c0-0.657 0.351-1.265 0.92-1.593s1.271-0.329 1.84 0c0.569 0.329 0.92 0.936 0.92 1.593v8.608c0 1.016-0.824 1.84-1.84 1.84s-1.84-0.824-1.84-1.84v-8.608zM16 21.392c1.016 0 1.84 0.824 1.84 1.84s-0.824 1.84-1.84 1.84c-1.016 0-1.84-0.824-1.84-1.84-0.004-0.489 0.188-0.96 0.534-1.306s0.817-0.538 1.306-0.534z" />
		</svg>
	);
}

export default CircleExclamationSVG;
