import React from 'react';
import { FormProps, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { AnyObject } from 'final-form';

type RocFormProps<TFormValues = AnyObject> = FormProps<TFormValues>;

const focusOnError = createDecorator();

/**
 * Wrapper component for react-final-form so that we can add useful defaults across the board.
 * @param props props
 */
export default function RocForm<TFormValues = AnyObject>({
	onSubmit,
	render,
	debug,
	destroyOnUnregister,
	form,
	component,
	initialValues,
	initialValuesEqual,
	keepDirtyOnReinitialize,
	validate,
	validateOnBlur,
	decorators = [focusOnError],
	mutators,
	subscription,
}: RocFormProps<TFormValues>) {
	return (
		<Form<TFormValues>
			onSubmit={onSubmit}
			decorators={decorators}
			render={render}
			debug={debug}
			destroyOnUnregister={destroyOnUnregister}
			form={form}
			component={component}
			initialValues={initialValues}
			initialValuesEqual={initialValuesEqual}
			keepDirtyOnReinitialize={keepDirtyOnReinitialize}
			mutators={mutators}
			subscription={subscription}
			validate={validate}
			validateOnBlur={validateOnBlur}
		/>
	);
}
