import React from 'react';
import StarRating from '~/shared/components/star-rating/StarRating';
import { Field } from 'react-final-form';
import OptionalFieldMarker from '../OptionalFieldMarker';
import ValidationMessages from './ValidationMessages';
import { FormFieldProps } from '.';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import { LocalizedSpan } from 'services/Localizer';
import { toKebabCase } from 'util/StringUtil';

export interface StarRatingFieldProps extends FormFieldProps {
	/**
	 * The maximum number of stars to display (default is 5)
	 */
	maxRating?: number;
}

/**
 * Reusable RFF-compatible field that displays a star rating input
 * @param props
 */
export default function StarRatingField({
	name,
	label = '',
	readOnly = false,
	disabled = false,
	maxRating = 5,
	isRequired = true,
	testId,
}: StarRatingFieldProps) {
	return (
		<Field name={name}>
			{({ input: { value, onChange }, meta }) => (
				<FieldInputErrorWrapper name={name} inline>
					<fieldset className="roc-rating">
						<legend className="roc-form-input-label">
							{label} {!isRequired ? <OptionalFieldMarker /> : null}
						</legend>

						<div className="roc-rating__group">
							<StarRating
								value={value}
								onChange={onChange}
								maxRating={maxRating}
								name={name}
								readonly={readOnly || disabled}
								testId={testId || `field-${toKebabCase(name)}`}
							/>
						</div>

						<div className="roc-rating__text roc-visually-hidden">
							<LocalizedSpan
								resourceKey="XOutOfYStars"
								args={[
									`<span className="roc-rating__text-current">${value}</span>
							<span id=${name} className="roc-rating__text-max">`,
									`<span className="roc-rating__text-max-number">${maxRating}</span>
							</span>`,
								]}
							/>
						</div>

						<ValidationMessages id={name + '-invalid'} meta={meta} />
					</fieldset>
				</FieldInputErrorWrapper>
			)}
		</Field>
	);
}
