import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Pencil SVG
 *
 * @returns
 */
function PencilSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('roc-icon roc-half-half-layout', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M27 0c2.761 0 5 2.239 5 5 0 1.126-0.372 2.164-1 3l-2 2-7-7 2-2c0.836-0.628 1.874-1 3-1zM2 23l-2 9 9-2 18.5-18.5-7-7-18.5 18.5zM22.362 11.362l-14 14-1.724-1.724 14-14 1.724 1.724z"></path>
		</svg>
	);
}

export default PencilSVG;
