// Extra large desktop breakpoint.
export const xlg = 1200;
export const xlgEm = '75em';

// Desktop breakpoint.
export const lg = 992;
export const lgEmMin = '62em';
export const lgEmMax = '61.9375em';

// Tablet breakpoint.
export const md = 768;
export const mdEmMin = '48em';
export const mdEmMax = '47.9375em';

// Mobile breakpoint.
export const sm = 576;
export const smEm = '36em';

//Extra small mobile breakpoint.
export const sx = 320;
export const sxEm = '20em';
