import { PricingResult } from './pricing-result';

/**
 * Represents a display pricing service result containing all necessary details to render a set of product prices.
 */
export type DisplayPricingResult = PricingResult<DisplayPrice>;

/**
 * Represents a simple price intended to be rendered.
 * Comparisons and arithmetic between prices is illegal besides !== and ===.
 * This is not suitable for transactions and attempting to use this for transactions is erroneous.
 */
export type Price = string;

/**
 * Represents an inclusive range of prices. It is erroneous for a price span no length (eg the
 * from and to field are the same), as this should be represented by a @see Price instead.
 *
 * PriceSpan is typically used for ambiguous ranges
 */
export type PriceSpan = {
	/**
	 * The lower bound of the price. If this is associated with a group of products, this
	 * is the price of the cheapest associated product.
	 */
	from: Price;

	/**
	 * The upper bound of the price. If this is associated with a group of products, this
	 * is the price of the most expensive associated product.
	 */
	to: Price;
};

/**
 * Represents a displayable price of unknown type.
 */
export type VariantPrice = Price | PriceSpan;

/**
 * The display rules for rendering a price on the frontend.
 */
export enum PriceDisplayRule {
	/**
	 * Represents a lack of a price. This is typically used for a product
	 * that isn't purchaseable. Distinct from free.
	 *
	 * If a price is missing, it is illegal to use any display price type besides @see MissingPrice
	 */
	Missing = 'Missing',

	/**
	 * Represents a normal price or price range. Expected price types are @see PriceSpan and @see Price
	 */
	Normal = 'Normal',

	/**
	 * Represents a price without a clearly defined upperbound. This can happen if multiple products in a group product
	 * do not share a common unit of measure. Expected price types are @see Price
	 */
	AmbiguousRange = 'AmbiguousRange',
}

/**
 * Represents a pricing object that is guaranteed to have a base price
 */
export interface HasBasePrice<TPrice extends VariantPrice = VariantPrice> {
	/** The price of the product if it isn't on sale. */
	basePrice: PartialDisplayPrice<TPrice>;
}

/**
 * Represents a pricing object that is guaranteed to have a sale price
 */
export interface HasSalePrice<TPrice extends VariantPrice = VariantPrice> {
	/** The sale price of the product. This is always less than the base price. */
	salePrice: PartialDisplayPrice<TPrice>;
}

/**
 * Represents a pricing object that has no base price, that is, it cannot be purchased.
 */
export interface HasNoBasePrice {
	/** The item in question has no base price and as such, it is illegal to access it. */
	basePrice?: never;
}

/**
 * Represents a pricing object that has no sale price
 */
export interface HasNoSalePrice {
	/** The item in question has no sale price and as such, it is illegal to access it. */
	salePrice?: never;
}

/**
 * Represents a "primitive" price object that would be displayed.
 * A product that has a base price and a sale price would have two unit display prices for example.
 */
export type PartialDisplayPrice<TPrice extends VariantPrice = VariantPrice> = TPrice extends Price
	? UnitPrice<TPrice> & HasRawValue
	: UnitPrice<TPrice>;

interface UnitPrice<TPrice extends VariantPrice = VariantPrice> {
	/** The unit of measure of the item/product being sold. This is typically appended to a price to yield a value like "$2.99/ea" */
	unit?: { label: string };

	/** The price value to be displayed. */
	value: TPrice;
}

interface HasRawValue {
	/**
	 * The raw price value for use in comparing prices to eachother or checking if they are zero or negative.
	 * It is not safe to assume this value for display purposes or price calculations.
	 */
	rawValue: number;
}

/**
 * Constrains a VariantPrice to a more specific price type
 */
type Filter<T extends VariantPrice, U extends VariantPrice> = T extends U ? T : never;

/**
 * Represents a price object that can be displayed.
 */
export type DisplayPrice<
	TBasePrice extends VariantPrice = VariantPrice,
	TSalePrice extends VariantPrice = TBasePrice
> =
	| SimpleDisplayPrice<TBasePrice, TSalePrice>
	| AmbiguousDisplayPrice<Filter<TBasePrice, Price>, Filter<TSalePrice, Price>>
	| MissingDisplayPrice;

/**
 * Represents a missing price, typically if used on an object not for a sale or an erroneous object.
 * This would either not be rendered or be rendered as "Not For Sale"
 */
export interface MissingDisplayPrice {
	/** The display rules for rendering a price on the frontend.
	 *
	 * A "missing" or "invisible" price is for a product that is not on sale and thus should not have
	 * a price rendered on screen */
	displayRule: PriceDisplayRule.Missing;
}

/**
 * Represents a simple price, that is a price that might be on sale with fixed upper and lower bounds. The unit
 * of measure for the base and sale price (if present) is expected to be the same
 */
export interface SimpleDisplayPrice<
	TBasePrice extends VariantPrice = VariantPrice,
	TSalePrice extends VariantPrice = TBasePrice
> extends HasBasePrice<TBasePrice>,
		Partial<HasSalePrice<TSalePrice>> {
	/** The display rules for rendering a price on the frontend.
	 *
	 * A normal price is either a simple price like "$3.12/ea" or a range like "$2.00-$5.00/ea"
	 */
	displayRule: PriceDisplayRule.Normal;
}

/**
 * Represents a price without a predictable upperbound for the price. This would typically be used if a group of products
 * didn't have a common unit of measure.
 */
export interface AmbiguousDisplayPrice<TBasePrice extends Price = Price, TSalePrice extends Price = TBasePrice>
	extends HasBasePrice<TBasePrice>,
		Partial<HasSalePrice<TSalePrice>> {
	/** The display rules for rendering a price on the frontend.
	 *
	 * An ambiguous price doesn't have a a specifiable upperbound because there isn't a common unit of measure
	 * to use as a baseline for what the most expensive unit of measure is. This is will appear like "From $2.00"
	 */
	displayRule: PriceDisplayRule.AmbiguousRange;
}
