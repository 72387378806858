import { BannerGroupRequest, BannerPlaceholderDetails, BannersForGroupsResponse } from 'features/types/banners';
import AxiosHelper from 'services/AxiosHelper';
import { lazyElements, lazyLoadImages } from 'shared/assets/lazy';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import 'styles/node-modules/tiny-slider/dist/tiny-slider.css';
import { updateCarouselItemsVisibility } from 'shared/carousels-a11y';
import setupImageBanners from './banners.images';

//#region Commerce
import setupProductBanners from './commerce/banners.products';
import { sx, md, lg, xlg } from 'shared/breakpoints';
import { TrackingListDataset } from 'features/tracking/trackers/commerce/types';
import { setupViewListObservers } from 'features/tracking/trackers/commerce/google-analytics-enhanced-ecommerce/google-analytics-view-list-observer-setup';
//#endregion Commerce

/**
 * Generates a key for the provided banners group as a combination of groupCode, pageUrl, isRandom and index (for random banners ONLY)
 * @param bannerGroupRequest
 * @param index
 */
function generateKeyForGroupRequest(bannerGroupRequest: BannerGroupRequest, index: number): string {
	let key = '';

	if (bannerGroupRequest.groupCode) {
		key += bannerGroupRequest.groupCode + '_';
	}

	if (bannerGroupRequest.pageUrl) {
		key += bannerGroupRequest.pageUrl + '_';
	}

	key += bannerGroupRequest.isRandom ? 0 : 1;

	// append element's index in the group key only for random banners
	if (bannerGroupRequest.isRandom) {
		key += '_' + index.toString();
	}

	return key;
}

/**
 * Setup function for both client and server side banners.
 */
function setupBanners() {
	setupServerBanners();
	setupClientBanners();
}

/**
 * Setup function for the server-side rendered banners that is responsible for setting up tracking/click events and initializing sliders
 */
function setupServerBanners() {
	// find all server-side rendered containers with banners
	const serverBannerContainers = document.querySelectorAll<HTMLElement>('[data-banner-type="server"]');

	if (!serverBannerContainers) {
		return;
	}

	// process server-side rendered containers to set them up
	for (let i = 0; i < serverBannerContainers.length; i++) {
		const serverBannersContainer = serverBannerContainers.item(i);
		//#region Commerce
		const serverBannerGroupDisplayName = serverBannersContainer.dataset.displayName;
		//#endregion Commerce

		// setup click event/impressions for all banners in the current container
		for (let j = 0; j < serverBannersContainer.childElementCount; j++) {
			const banner = serverBannersContainer.children.item(j) as HTMLElement;
			setupBannerClick(banner);

			const bannerData = banner.dataset;

			if (bannerData.name) {
				dispatchRocEvent('banner-impression', { bannerName: bannerData.name });
			}
		}

		// setup slider/carousel
		const isCarousel = serverBannersContainer.classList.contains('roc-slider');
		//#region Commerce
		const isProductBannerGroup = serverBannersContainer.dataset.isProductTypeGroup?.toLowerCase() === 'true';
		const isMixedBannerGroup = serverBannersContainer.dataset.isMixedBannerGroup?.toLowerCase() === 'true';
		//#endregion Commerce

		if (isCarousel) {
			//#region Commerce
			if (isProductBannerGroup) {
				const carouselDisplayName = serverBannerGroupDisplayName ? serverBannerGroupDisplayName.trim() : '';
				const carouselHeading = document.createElement('h2');

				carouselHeading.classList.add('roc-slider__label');
				carouselHeading.appendChild(document.createTextNode(carouselDisplayName));
				serverBannersContainer.parentNode?.insertBefore(carouselHeading, serverBannersContainer);
			}

			//#endregion Commerce
			setupSliderForCarousel(
				serverBannersContainer,
				//#region Commerce
				isProductBannerGroup,
				isMixedBannerGroup,
				//#endregion Commerce
			);
		}
	}
}

/**
 * Setup function for the client-side banners that is responsible for populating all the banners on a page
 */
async function setupClientBanners() {
	const bannerPlaceholders = document.querySelectorAll<HTMLElement>('[data-banner-type="client"]');

	if (!bannerPlaceholders) {
		return;
	}

	const bannerGroupsPlaceholderDetails: BannerPlaceholderDetails[] = [];

	// go over each banner placeholder and gather details for each element
	for (let i = 0; i < bannerPlaceholders.length; i++) {
		const bannerPlaceholder = bannerPlaceholders.item(i);
		const placeholderData = bannerPlaceholder.dataset;

		// details based on data-* attributes
		const requestData = {
			groupCode: placeholderData.groupCode,
			isRandom: placeholderData.isRandom === 'true',
			pageUrl: placeholderData.pageUrl,
		};

		// generate unique key for this placeholder/group
		// the key will be used to process response from the server
		const groupKey = generateKeyForGroupRequest(requestData, i);

		// add details about this placeholder/group into an array that will be used to create a request object
		bannerGroupsPlaceholderDetails.push({
			key: groupKey,
			placeholder: bannerPlaceholder,
			requestData,
		});
	}

	if (bannerGroupsPlaceholderDetails.length === 0) {
		// no client-side banners found, so let's exit.
		return;
	}

	try {
		// create request object + remove duplicated groups
		const distinctBannerGroups = bannerGroupsPlaceholderDetails
			.map((group) => {
				return { ...group.requestData, key: group.key };
			})
			.filter((elem, index, self) => {
				return self.findIndex((t) => t.key === elem.key) === index;
			});

		// get all the banners for the marked landing zones
		const response = await AxiosHelper.post<BannersForGroupsResponse>('/ajax/banners', {
			groupCodes: distinctBannerGroups,
		});

		const bannersFromServer = response.data.bannersForGroups;
		//#region Commerce
		let bannerIndex = 0;
		//#endregion

		// go over each landing zone and populate banners
		for (const placeholderDetails of bannerGroupsPlaceholderDetails) {
			// ensure that there is data for the requested group by checking if server response has the requested key
			if (!bannersFromServer.hasOwnProperty(placeholderDetails.key)) {
				console.error(`No matching banner group was found on the server (key: ${placeholderDetails.key})`);
				continue;
			}
			const bannersGroups = bannersFromServer[placeholderDetails.key];
			const isCarousel = placeholderDetails.placeholder.classList.contains('roc-slider');
			//#region Commerce
			const isProductBannerGroup = bannersGroups.banners.every((b) => b.type == 'Product');
			const isMixedBannerGroup =
				bannersGroups.banners.some((b) => b.type == 'Product') &&
				!bannersGroups.banners.every((b) => b.type == 'Product');
			//#endregion Commerce
			for (const banner of bannersGroups.banners) {
				const htmlBanner = document.createElement('div');
				htmlBanner.dataset.name = banner.name;
				//#region Commerce
				bannerIndex++;
				//#endregion

				switch (banner.type) {
					case 'WYSIWYG':
						if (banner.customHtml) {
							htmlBanner.innerHTML = banner.customHtml;
						}

						break;
					case 'Image':
						setupImageBanners(
							htmlBanner,
							banner,
							isCarousel,
							placeholderDetails,
							//#region Commerce
							isMixedBannerGroup,
							//#endregion
						);

						break;
					//#region Commerce
					case 'Product':
						// Add elements to the div so that setupViewListObservers can read the data
						const trackingDataset = htmlBanner.dataset as TrackingListDataset;
						trackingDataset.rocTrackingProductInfo = JSON.stringify(
							banner.productTracking?.productTracking,
						);
						trackingDataset.rocTrackingListId = banner.productTracking?.itemListId;
						trackingDataset.rocTrackingListName = banner.productTracking?.itemListName;
						trackingDataset.rocTrackingListEnabled = true;

						const reactRoot = document.createElement('div');
						reactRoot.id = `banner_product_${placeholderDetails.key}_${bannerIndex}`;
						htmlBanner.appendChild(reactRoot);

						// We need to place this at the end of the callback queue, in order to finalize lazy loading
						// before the React app can be initialized.
						setTimeout(() => {
							setupProductBanners(
								reactRoot.id,
								banner,
								placeholderDetails,
								//#region Commerce
								isProductBannerGroup,
								isMixedBannerGroup,
								//#endregion Commerce
							);
						});
						break;
					//#endregion
				}

				setupBannerClick(htmlBanner);
				placeholderDetails.placeholder.appendChild(htmlBanner);
				dispatchRocEvent('banner-impression', { bannerName: banner.name }); // init banner impression
				//#region Commerce
				// set up observers for tracking these banner items
				setupViewListObservers();
				//#endregion
			}

			if (isCarousel) {
				//#region Commerce
				// Only show displayName if the BannerGroup is composed entirely of product banners
				if (isProductBannerGroup) {
					const carouselDisplayName = bannersGroups.displayName ? bannersGroups.displayName.trim() : '';
					const carouselHeading = document.createElement('h2');

					carouselHeading.classList.add('roc-slider__label');
					carouselHeading.appendChild(document.createTextNode(carouselDisplayName));
					placeholderDetails.placeholder.parentNode?.insertBefore(
						carouselHeading,
						placeholderDetails.placeholder,
					);
				}
				//#endregion Commerce

				setupSliderForCarousel(
					placeholderDetails.placeholder,
					//#region Commerce
					isProductBannerGroup,
					isMixedBannerGroup,
					//#endregion Commerce
				);
			} else {
				// client side banners are loaded with some delay, so the initial call to setup lazy loaded images
				// may have already run, so we need to call it ourselves.
				lazyLoadImages();
			}
		}
	} catch (error) {
		console.error('Unable to load banners:', error);
	}
}

/**
 * Setups click event for the banner
 *
 * @param banner
 */
function setupBannerClick(banner: HTMLElement) {
	banner.onclick = (event: MouseEvent) => {
		const clickedBannerData = (event.currentTarget as HTMLElement).dataset;
		if (clickedBannerData.name) {
			dispatchRocEvent('banner-click', { bannerName: clickedBannerData.name });
		}
	};
}

/**
 * Setups slider for the provided banner container
 *
 * @param bannerPlaceHolder
 */
function setupSliderForCarousel(
	bannerPlaceHolder: HTMLElement,
	//#region Commerce
	isProductBannerGroup: boolean,
	isMixedBannerGroup: boolean,
	//#endregion Commerce
) {
	if (bannerPlaceHolder.hasAttribute('has-tiny-slider')) {
		return;
	}

	bannerPlaceHolder.setAttribute('has-tiny-slider', 'true');

	lazyElements({
		elements: [bannerPlaceHolder],
		callback: (isIntersecting) => {
			// avoid rebinding if tiny slider has already been setup against the element.
			if (!isIntersecting || bannerPlaceHolder.classList.contains('tns-slider')) {
				return;
			}

			let customArgs;

			if (bannerPlaceHolder.dataset.customArgs) {
				customArgs = JSON.parse(bannerPlaceHolder.dataset.customArgs);
				for (const [key, value] of Object.entries(customArgs)) {
					if (key === 'responsive' && typeof value === 'string') {
						customArgs[key] = JSON.parse(value);
					}
				}
			}

			const showControls =
				(bannerPlaceHolder.dataset.showControls &&
					bannerPlaceHolder.dataset.showControls.toLowerCase() === 'true') ||
				false;

			const tnsPromise = import('tiny-slider/src/tiny-slider').then((module) => module.tns);

			//#region Commerce
			if (isProductBannerGroup || isMixedBannerGroup) {
				bannerPlaceHolder.dataset.autoplay = 'false';
				bannerPlaceHolder.dataset.nav = 'true';
			}
			//#endregion Commerce

			(async () => {
				const tns = await tnsPromise;

				const slider = tns({
					container: bannerPlaceHolder,
					items: (bannerPlaceHolder.dataset.items && parseInt(bannerPlaceHolder.dataset.items)) || 3,
					slideBy: (bannerPlaceHolder.dataset.slideBy && parseInt(bannerPlaceHolder.dataset.slideBy)) || 1,
					autoplay: bannerPlaceHolder.dataset.autoplay?.toLowerCase() === 'true',
					controls: showControls,
					nav:
						//#region Commerce
						bannerPlaceHolder.dataset.nav?.toLowerCase() === 'true' ||
						//#endregion Commerce
						showControls,
					autoplayButtonOutput: showControls,
					//#region Commerce
					responsive: getResponsiveOptions(isProductBannerGroup, isMixedBannerGroup),
					//#endregion Commerce
					...customArgs,
					onInit: () => {
						// on slider initialization we have to make sure that all banners are visible on the front-end
						// that's why we iterate through all of them and reset their display style which would be set to display:none on the server-side
						if (bannerPlaceHolder.childElementCount === 0) {
							return;
						}

						for (let i = 0; i < bannerPlaceHolder.childElementCount; i++) {
							const banner = bannerPlaceHolder.children.item(i) as HTMLElement;

							if (banner) {
								banner.style.display = '';
							}
						}

						lazyLoadImages();

						/**
						 * Set initial visibility rules for the active and non active banners
						 * to increase Lighthouse Accessibility score
						 */
						const activeBanners = bannerPlaceHolder.querySelectorAll('.tns-slide-active');
						const activeIndex = Array.from(bannerPlaceHolder.children).findIndex((b: HTMLElement) =>
							b.classList.contains('tns-slide-active'),
						);
						const activeItems = activeBanners.length - 1;

						updateCarouselItemsVisibility(activeIndex, activeItems, bannerPlaceHolder.children);
					},
				});

				slider.events.on('indexChanged', (info) => {
					const bannerData = (info.slideItems[info.index] as HTMLElement).dataset;
					if (bannerData.name) {
						dispatchRocEvent('banner-impression', { bannerName: bannerData.name });
					}

					/**
					 * Set visibility rules for the active and non active banners
					 * to increase Lighthouse Accessibility score
					 */
					const activeIndex = info.index;
					const activeItems = info.items - 1;
					const sliderBanners = info.slideItems;

					updateCarouselItemsVisibility(activeIndex, activeItems, sliderBanners);
				});
			})();
		},
	});
}

//#region Commerce
function getResponsiveOptions(isProductBannerGroup: boolean, isMixedBannerGroup: boolean) {
	const tnsResponsiveOption = {
		[sx]: {
			items: 1,
		},
		[md]: {
			items: 2,
		},
		[lg]: {
			items: 3,
		},
		[xlg]: {
			items: 6,
		},
	};

	return isProductBannerGroup || isMixedBannerGroup ? tnsResponsiveOption : false;
}
//#endregion Commerce

export default setupBanners;
