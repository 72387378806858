import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import CheckboxInputField from './CheckboxInputField';
import { BaseInputProps } from './InputField';

export type InputFieldProps = FieldProps<boolean, FieldRenderProps<boolean, HTMLElement>> & BaseInputProps;

/**
 * Component that renders a checkbox field
 */
export default function CheckBoxField({
	name,
	readOnly = false,
	disabled = false,
	label = '',
	//#region : EFG Customization
	htmlLabel,
	ariaRequired,
	ariaLive,
	//#endregion
	afterSubmit,
	allowNull,
	beforeSubmit,
	formatOnBlur,
	initialValue,
	isEqual,
	subscription,
	defaultValue,
}: InputFieldProps) {
	return (
		<Field
			name={name}
			type="checkbox"
			afterSubmit={afterSubmit}
			allowNull={allowNull}
			beforeSubmit={beforeSubmit}
			formatOnBlur={formatOnBlur}
			initialValue={initialValue}
			isEqual={isEqual}
			subscription={subscription}
			defaultValue={defaultValue}
		>
			{({ input, meta }) => (
				<CheckboxInputField
					name={name}
					input={input}
					meta={meta}
					readOnly={readOnly}
					disabled={disabled}
					label={label}
					//#region : EFG Customization
					htmlLabel={htmlLabel}
					ariaRequired={ariaRequired}
					ariaLive={ariaLive}
					//#endregion
					type="checkbox"
					defaultValue={defaultValue}
				/>
			)}
		</Field>
	);
}
