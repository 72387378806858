/**
 * Set Accessibility rules for active and non active slider items
 */
export function updateCarouselItemsVisibility(activeIndex, activeItems, sliderBanners) {
	for (let bannerIndex = 0; bannerIndex < sliderBanners.length; bannerIndex++) {
		const currentBanner = sliderBanners[bannerIndex] as HTMLElement;

		if (bannerIndex == activeIndex || (bannerIndex > activeIndex && bannerIndex <= activeIndex + activeItems)) {
			currentBanner.style.visibility = 'visible';
		} else {
			currentBanner.style.visibility = 'hidden';
		}
	}
}
