import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * User SVG
 *
 * @returns
 */
function UserSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 29 32"
			className={classnames('roc-icon roc-icon-user', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M20.8 19.2c4.48 0 8 3.52 8 8v3.2c0 0.96-0.64 1.6-1.6 1.6s-1.6-0.64-1.6-1.6v-3.2c0-2.72-2.080-4.8-4.8-4.8h-12.8c-2.72 0-4.8 2.080-4.8 4.8v3.2c0 0.96-0.64 1.6-1.6 1.6s-1.6-0.64-1.6-1.6v-3.2c0-4.48 3.52-8 8-8h12.8zM14.4 16c-4.48 0-8-3.52-8-8s3.52-8 8-8c4.48 0 8 3.52 8 8s-3.52 8-8 8zM14.4 3.2c-2.72 0-4.8 2.080-4.8 4.8s2.080 4.8 4.8 4.8c2.72 0 4.8-2.080 4.8-4.8s-2.080-4.8-4.8-4.8z" />
		</svg>
	);
}

export default UserSVG;
