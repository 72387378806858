import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Check circle SVG
 *
 * @returns
 */
function CheckCircleSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('roc-icon roc-icon-check-circle', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
			<path d="M12.316 21.997l-3.565-4.619c-0.853-2.182 0.962-2.929 2.306-1.679l2.308 2.94 7.136-7.977c1.259-1.323 3.358-0.567 2.519 1.47l-8.397 9.655c-0.902 1.050-1.679 0.841-2.308 0.213z" />
		</svg>
	);
}

export default CheckCircleSVG;
