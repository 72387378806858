import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Folder SVG
 *
 * @returns
 */
function FolderSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 52 32"
			className={classnames('roc-icon roc-icon-folder', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path
				fill="#231f20"
				d="M20.568 22.146l1.85 4.385c0.112 0.255 0.415 0.367 0.718 0.494 0.412-0.037 0.786-0.168 1.11-0.373l-0.010 0.006 6.713-4.257c0.973-0.622 1.419-1.371 1.196-1.881s-1.084-0.558-1.945 0v0l-3.189 2.009 4.114-7.382c0.446-0.765 0.175-1.387-0.574-1.387-0.928 0.091-1.715 0.62-2.161 1.374l-0.007 0.013-4.145 7.382-0.893-2.137c-0.223-0.542-1.1-0.558-1.961 0v0c-0.505 0.302-0.837 0.847-0.837 1.468 0 0.101 0.009 0.2 0.026 0.296l-0.001-0.010z"
			/>
			<path
				fill="#000"
				d="M48.933 11.161l-9.774 17.682c-0.258 0.46-0.742 0.765-1.298 0.765-0.003 0-0.007 0-0.010-0h-34.822l1.754-3.189 7.797-14.35c0.225-0.518 0.721-0.88 1.304-0.909l0.003-0h35.077zM13.537 0h-12.277c-0.696 0-1.26 0.564-1.26 1.26v0 29.178c0.006 0.454 0.201 0.861 0.509 1.147l0.001 0.001s0 0 0-0.112c0.323 0.325 0.771 0.526 1.266 0.526 0.003 0 0.007 0 0.010-0h36.065c0.003 0 0.006 0 0.009 0 1.449 0 2.712-0.796 3.377-1.974l0.010-0.019 10.268-18.543c0.137-0.249 0.217-0.546 0.217-0.861 0-1.002-0.81-1.814-1.811-1.818h-10.061v-4.592c0-0.881-0.714-1.594-1.594-1.594v0h-23.47v-1.339c0-0.696-0.564-1.26-1.26-1.26v0zM2.679 25.24v-22.322h9.567v2.599h25.511v3.269h-23.916c-1.436 0.022-2.681 0.818-3.338 1.989l-0.010 0.020-7.813 14.445z"
			/>
		</svg>
	);
}

export default FolderSVG;
