import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Credit Card Simple SVG
 *
 * @returns
 */
function CreditCardSimpleSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 43 32" className={classnames('roc-icon roc-icon-credit-card-simple', className)} {...rest}>
			<path d="M37.333 0h-32c-3.022 0-5.333 2.311-5.333 5.333v21.333c0 3.022 2.311 5.333 5.333 5.333h32c3.022 0 5.333-2.311 5.333-5.333v-21.333c0-3.022-2.311-5.333-5.333-5.333zM5.333 3.556h32c1.067 0 1.778 0.711 1.778 1.778v5.333h-35.556v-5.333c0-1.067 0.711-1.778 1.778-1.778zM37.333 28.444h-32c-1.067 0-1.778-0.711-1.778-1.778v-12.444h35.556v12.444c0 1.067-0.711 1.778-1.778 1.778z" />
		</svg>
	);
}

export default CreditCardSimpleSVG;
