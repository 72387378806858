import classnames from 'classnames';
import React, { ImgHTMLAttributes, useEffect, useState } from 'react';
import { getAssetHost, ServerData } from 'services/ServerData';
import { WithTestIdOptional } from 'shared-client/types/test';
import { url } from 'util/Urls';
import { ImageClassInfo } from './types';

export interface ResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement>, WithTestIdOptional, ImageClassInfo {
	/**
	 * The alt tag for this image.
	 */
	alt?: string;
	/**
	 * The image dimension to use for the image's `<img>` tag's `src` component.
	 * If not provided, uses the largest breakpoint dimension's url as the src of the image, then the "general" image dimension, and if no image dimensions (`responsiveImageUrls`) are available, uses `fallbackSrc`.
	 */
	fallbackDimension?: string;
	/**
	 * The image URL to use for the image's `<img>` tag's `src` component. Used only if all other methods of determining the image's `src` fail, or if no image dimensions (`responsiveImageUrls`) are provided
	 */
	fallbackSrc?: string;
	/**
	 * A set of breakpoint => dimension mappings, either as an object in a format like:
	 *
	 * `{ sm: 'tiny', xl: 'large' }`,
	 *
	 * or as a string in a format of
	 *
	 * `"sm:tiny;md:medium;lg:large"`
	 */
	breakpointDimensions?: Record<string, string> | string;
	/**
	 * A set of image dimension to image url mappings, in a format like `{ tiny: '/path/to/tiny/image.jpg', large: '/path/to/large/image.jpg' }`.
	 * These should normally be provided by the server.
	 */
	responsiveImageUrls?: Record<string, string>;
	/**
	 * Whether to lazy-load this image using the new `loading="lazy"` `img` property. Should not be set for images that will display above the fold.
	 */
	lazyLoaded?: boolean;

	onLoad?: () => void;
}

/**
 * Information about one responsive image entry, mapping the maxWidth to the responsive URL
 */
interface ResponsiveImageInfo {
	breakpointCode: string;
	maxWidth: number;
	responsiveUrl: string;
}

/**
 * Return breakpoint dimensions as an object of {breakpoint:dimension} pairs, from a passed in dimensionInfo object or string.
 */
function getBreakpointDimensions(dimensionInfo?: Record<string, string> | string): Record<string, string> | undefined {
	if (!dimensionInfo) {
		// Can't do anything with this, return undefined
		return undefined;
	}

	if (typeof dimensionInfo != 'string') {
		// This is already the type we want, return it
		return dimensionInfo;
	}

	// Parse the dimension info string
	const result: Record<string, string> = {};

	// first split by semicolon
	const breakpointPairs = dimensionInfo.split(';');
	for (const breakpointPair of breakpointPairs) {
		// next split by colon
		const breakpointInfo = breakpointPair.split(':');

		// make sure there are 2 entries, for the left and right sides of the colon
		if (breakpointInfo.length != 2) {
			console.warn('Invalid breakpoint to image dimension mapping, ignoring:', breakpointPair);
			continue;
		}

		// add the breakpoint:dimension mapping to the result
		result[breakpointInfo[0].trim().toLowerCase()] = breakpointInfo[1].trim().toLowerCase();
	}
	return result;
}

/**
 * Responsive Image component, optionally lazy loaded by passing in the `lazyLoaded` parameter in the props.
 *
 * Renders an image inside a picture tag with the correct &lt;source&gt; entries to serve different images based on the breakpoints and image dimensions set up in the admin,
 * and the breakpoint:dimension mappings provided in the props.
 *
 * The src is set in this order:
 * 1. fallbackDimension
 * 2. Largest (by max-width) provided breakpoint:dimension mapping image url
 * 3. "general" dimension
 * 4. fallbackSrc
 * 5. "images/image-not-found.png"
 */
export default function ResponsiveImage(props: ResponsiveImageProps) {
	const {
		alt,
		fallbackDimension,
		fallbackSrc,
		breakpointDimensions: propBreakpointDimensions,
		responsiveImageUrls,
		lazyLoaded = false,
		testId = 'responsive-image',
		delay = 0,
		className,
		inactiveClassName = 'roc-lazy-image--inactive',
		loadedClassName = 'roc-lazy-image--loaded',
		onLoad,
		...rest
	} = props;

	const [imageLoaded, setImageLoaded] = useState(false);
	const [loadedClassDisplayed, setLoadedClassDisplayed] = useState(false);

	useEffect(() => {
		if (imageLoaded) {
			setTimeout(() => {
				setLoadedClassDisplayed(true);
			}, delay);
		}
	}, [imageLoaded, delay]);

	// Get breakpoint dimensions in the correct format
	const breakpointDimensions = getBreakpointDimensions(propBreakpointDimensions);

	/**
	 * Get the path to the image from the passed-in image dimension. Returns null if the dimension's URL was not provided
	 */
	const getImagePathFromImageDimension = (dimensionName: string) => {
		if (!responsiveImageUrls) {
			console.warn(`Image dimension paths not provided, defaulting to just using the src element.`);
			return null;
		}

		const validDimensions = Object.keys(responsiveImageUrls);
		const foundDimension = validDimensions.find(
			(d) => d.trim().toLowerCase() === dimensionName.trim().toLowerCase(),
		);
		if (!foundDimension) {
			console.warn(`Could not find a valid dimension with the specified name "${dimensionName}"!`);
			return null;
		}
		return responsiveImageUrls[foundDimension];
	};

	const serverBreakpoints = ServerData.RESPONSIVE_IMAGE_BREAKPOINTS ?? {};

	const calculatedBreakpointData: ResponsiveImageInfo[] = [];

	// If breakpointDimensions were provided, extract them to the requested breakpoint data
	if (breakpointDimensions) {
		const requestedBreakpointKeys = Object.keys(breakpointDimensions);
		const serverBreakpointKeys = Object.keys(serverBreakpoints);

		for (const requestedBreakpointKey of requestedBreakpointKeys) {
			// Decide if the current breakpoint is in both the server-provided breakpoints and passed-in breakpoints
			const foundBreakpointKey = serverBreakpointKeys.find(
				(serverBpKey) => serverBpKey.trim().toLowerCase() === requestedBreakpointKey.trim().toLowerCase(),
			);

			if (!foundBreakpointKey) {
				// If the requested breakpoint was not found in the server-side data, warn and ignore
				console.warn(
					`Could not find server-side breakpoint information for the requested breakpoint "${requestedBreakpointKey}". Will not render this breakpoint in the DOM.`,
				);
				continue;
			}

			const requestedDimension = breakpointDimensions[requestedBreakpointKey];
			const currentDimensionPath = getImagePathFromImageDimension(requestedDimension);
			if (currentDimensionPath != null) {
				const maxWidth = serverBreakpoints[foundBreakpointKey];
				calculatedBreakpointData.push({
					breakpointCode: foundBreakpointKey,
					maxWidth: maxWidth,
					responsiveUrl: currentDimensionPath,
				});
			}
		}
	}

	// Sort the breakpoints from smallest to largest maxWidth, since browsers render the first source entry that matches, so if the largest one is at the top, only that one will be matched
	const requestedBreakpointData = calculatedBreakpointData.sort((item1, item2) => item1.maxWidth - item2.maxWidth);

	// Figure out the image's `src` value, based on fallback dimension or largest requested breakpoint
	let imageSrc: string | undefined | null = undefined;
	if (fallbackDimension) {
		imageSrc = getImagePathFromImageDimension(fallbackDimension);
	}
	if (!imageSrc && requestedBreakpointData?.length > 0) {
		imageSrc = requestedBreakpointData[requestedBreakpointData.length - 1].responsiveUrl;
	}
	if (!imageSrc && responsiveImageUrls) {
		imageSrc = getImagePathFromImageDimension('tiny');
	}
	if (!imageSrc && fallbackSrc) {
		imageSrc = fallbackSrc;
	}

	if (requestedBreakpointData.length === 0) {
		console.warn('No valid responsive data combinations found. Responsive rendering not available.');
	}

	const onResponsiveImageLoad = () => {
		setImageLoaded(true);
		onLoad && onLoad();
	};

	const classes = classnames(className, {
		[inactiveClassName]: !imageLoaded,
		[loadedClassName]: loadedClassDisplayed,
	});

	// Responsive images can either be rendered using the <picture> tag with multiple <source> elements (https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#art_direction),
	// or just an <img> tag with multiple `srcset` and `sizes` entries (https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_different_sizes).
	// Currently we use the first approach.
	return (
		<picture data-testid={`${testId}-picture`}>
			{requestedBreakpointData.map((resource) => (
				<source
					key={resource.breakpointCode}
					media={`(max-width:${resource.maxWidth}px)`}
					srcSet={url(resource.responsiveUrl)}
					data-testid={`${testId}-source-${resource.maxWidth}`}
				/>
			))}
			<img
				src={url(imageSrc ?? `${getAssetHost()}/images/image-not-found.png`)}
				alt={alt}
				className={classes}
				loading={lazyLoaded ? 'lazy' : undefined}
				data-testid={`${testId}-img`}
				onLoad={onResponsiveImageLoad}
				{...rest}
			/>
		</picture>
	);
}
