/**
 * Perform module setup for product details page tabs
 */
export default function setupPDPTabs() {
	const menu: HTMLElement = document.querySelector('.roc-pdp-sections__nav') as HTMLElement;
	const trigger: HTMLElement = document.querySelector('.roc-pdp-sections__nav-trigger') as HTMLElement;

	if (!menu || !trigger) {
		return;
	}

	const handleToggle = () => menu.classList.toggle('roc-pdp-sections__nav--show');

	trigger.addEventListener('keyup', (event) => {
		if (event.keyCode === 13 || event.keyCode === 32) {
			handleToggle();
		}
	});

	trigger.onclick = () => handleToggle();

	window.onclick = (e) => {
		if (e.target !== menu && !trigger.contains(e.target)) {
			menu.classList.remove('roc-pdp-sections__nav--show');
		}
	};
}
