import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ValidationMessages } from '.';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import { BaseInputProps } from './InputField';
import { toKebabCase } from 'util/StringUtil';

export type InputFieldProps = FieldRenderProps<boolean, HTMLElement> &
	BaseInputProps & {
		placeholder?: string;
		//#region : EFG Customization
		htmlLabel?: any;
		//#endregion
	};

/**
 * Component that renders a checkbox input with form field.
 */
export default function CheckboxInputField({
	input: { name, onChange, value },
	meta,
	type,
	label = '',
	//#region : EFG Customization
	htmlLabel,
	ariaLive,
	ariaRequired,
	//#endregion
	disabled = false,
	readOnly = false,
	placeholder = '',
	step,
	testId,
	defaultValue = false,
}: InputFieldProps) {
	return (
		<div className={meta.dirty ? 'roc-form-group form-group--dirty' : 'roc-form-group'}>
			<div className="roc-form-input__input-checkbox-wrapper">
				<FieldInputErrorWrapper name={name} inline={type === 'checkbox'}>
					<label htmlFor={name} className="roc-form-input__input-checkbox-label">
						<input
							className="roc-form-input__input roc-form-input__input"
							id={name}
							name={name}
							checked={value}
							type="checkbox"
							onChange={onChange}
							defaultChecked={defaultValue}
							disabled={disabled}
							step={step}
							readOnly={readOnly}
							aria-describedby={name + '-invalid'}
							//#region : EFG Customization
							aria-required={ariaRequired}
							aria-live={ariaLive}
							//#endregion
							placeholder={placeholder}
							data-testid={testId || `field-${toKebabCase(name)}`}
						/>
						{/*#region : EFG Customization*/}
						<span className="roc-form-input__input-checkbox-label-text"> {htmlLabel ? htmlLabel : label}</span>
						{/*#endregion*/}
					</label>
				</FieldInputErrorWrapper>
			</div>
			<ValidationMessages id={name + '-invalid'} meta={meta} />
		</div>
	);
}
