import React from 'react';
import { LocationDto } from './types';
import MapPinSVG from 'shared/components/svg/MapPinSVG';
import type MediaQuery from 'react-responsive';
import Spinner from 'shared/components/Spinner';
import { Localizer } from 'services/Localizer';
const LazyMediaQuery = React.lazy<typeof MediaQuery>(() => import('react-responsive'));

interface StoreLocationFormattedAddressProps {
	/**
	 * The store location to display
	 */
	location: LocationDto;
	/**
	 * The distance unit, if any
	 */
	distanceUnit?: string;
	/**
	 * The callback fired when the location name is clicked
	 */
	onPinClicked?: () => void;

	bigTitle?: boolean;

	showTitle?: boolean;

	index?: number;
}

/**
 * Component that displays the address and pin for any particular location
 *
 * @export
 * @param {StoreLocationFormattedAddressProps} props
 * @returns
 */
export default function StoreLocationFormattedAddress({
	location,
	distanceUnit = 'mi',
	onPinClicked,
	bigTitle = false,
	showTitle = true,
	index = 0,
}: StoreLocationFormattedAddressProps) {
	const Pin = onPinClicked ? (
		<button
			onClick={() => {
				onPinClicked();
			}}
			type="button"
			className="roc-store-locator-address__map-pin"
			aria-label={Localizer('Location') + ' ' + location.name}
		>
			<MapPinSVG className="roc-store-locator-address__map-pin-icon" />
		</button>
	) : null;

	return (
		<React.Suspense fallback={<Spinner />}>
			<div className="roc-store-locator-address">
				<LazyMediaQuery minWidth={992}>{Pin}</LazyMediaQuery>

				{showTitle && bigTitle ? (
					<h1
						className="roc-store-locator-address__name roc-store-locator-address__name--big"
						data-testid={`store-location-name-${index}`}
					>
						{location.name}
					</h1>
				) : showTitle ? (
					<h2 className="roc-store-locator-address__name" data-testid={`store-location-name-${index}`}>
						{location.name}
					</h2>
				) : null}

				<span className="roc-store-locator-address__address-one">{location.address1}</span>

				{location.address2 ? (
					<span className="roc-store-locator-address__address-two">{location.address2}</span>
				) : null}

				<span className="roc-store-locator-address__city">{location.city}, </span>

				<span className="roc-store-locator-address__region">
					{location.country === 'US' || location.country === 'CA'
						? location.state
							? location.state + ' '
							: ' '
						: location.region
						? location.region + ' '
						: ' '}
				</span>

				<span className="roc-store-locator-address__postal-code">{location.postalCode}</span>

				{location.phoneNumber ? (
					<span className="roc-store-locator-address__phone">
						<a href={'tel:' + location.phoneNumber}>{location.phoneNumber}</a>
					</span>
				) : null}

				{location.distance ? (
					<span className="roc-store-locator-address__distance">
						{location.distance.toFixed(2)}
						{distanceUnit}
					</span>
				) : null}

				<LazyMediaQuery maxWidth={991} minWidth={768}>
					{Pin}
				</LazyMediaQuery>
			</div>
		</React.Suspense>
	);
}
