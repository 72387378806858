import React from 'react';
import { Form } from 'react-final-form';
import { Localizer } from 'services/Localizer';
import { TextBoxField } from 'shared/edit/fields';

interface StoreLocatorSearchFormProps {
	/**
	 * The initial search value
	 */
	initSearchValue?: string;
	/**
	 * The flag indicating whether the search button is enabled or not
	 */
	isEnabled: boolean;
	/**
	 * The callback when the search button has been clicked
	 */
	onSearch: (location: string) => void;
	/**
	 * Optional CSS class wrapper for the form
	 */
	className?: string;
}

export interface StoreLocatorSearchFormValues {
	searchText: string;
}

export interface StoreLocatorSearchFormErrorValues {
	searchText: string;
}

/**
 * Reusable component for rendering store location search form with validation out of the box.
 * Supports initial value if it needs to be prepopulated
 * @param props
 */
export default function StoreLocatorSearchForm(props: StoreLocatorSearchFormProps) {
	const { onSearch, isEnabled, initSearchValue, className = '' } = props;

	const onFormSubmit = (formValues: StoreLocatorSearchFormValues) => {
		onSearch(formValues.searchText || '');
	};

	return (
		<Form<StoreLocatorSearchFormValues>
			onSubmit={onFormSubmit}
			initialValues={{
				searchText: initSearchValue || '',
			}}
			subscription={{ pristine: true, dirty: true, valid: true }}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit} className={'roc-store-locator-modal-search-form ' + className}>
						<div className="roc-store-locator-modal-search-form__wrapper">
							<div className="roc-store-locator-modal-search-form__input">
								<TextBoxField
									name="searchText"
									placeholder={Localizer('StoreSearchPlaceholder')}
									validate={(value) => {
										return value && value.trim()
											? undefined
											: Localizer('StoreSearchInvalidErrorMessage');
									}}
									testId="store-modal-search-text-input"
								/>
							</div>

							<div className="roc-store-locator-modal-search-form__btn-wrapper">
								<button
									type="submit"
									className="roc-btn roc-store-locator-modal-search-form__btn"
									disabled={!isEnabled}
									data-testid="store-modal-search-button"
								>
									{Localizer('FindStore')}
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
}
