import AccessibleNavigation from './accessible-navigation';

export default function setupNavigation() {
	const navElementID = 'main-navigation';

	const navElement = document.getElementById(navElementID);

	if (!navElement) {
		console.warn(`An element with id '${navElementID}' was not found. Accessible navigation was not initialized.`);
	} else {
		new AccessibleNavigation({ navElement, mobileBreakpoint: 991 });

		navElement.style.removeProperty('display');
	}
}
