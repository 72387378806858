import { Banner, BannerPlaceholderDetails } from 'features/types/banners';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ResponsiveImage from 'shared/assets/ResponsiveImage';

//#region Commerce
import classnames from 'classnames';
//#endregion

export default function setupImageBanners(
	htmlBanner: HTMLElement,
	banner: Banner,
	isCarousel: boolean,
	placeholderDetails: BannerPlaceholderDetails,
	//#region Commerce
	isMixedBannerGroup: boolean,

	//#endregion
) {
	// allow let, this is needed in commerce.
	// eslint-disable-next-line prefer-const
	let classes = 'roc-lazy-image';
	//#region Commerce
	classes += ' roc-product-list__image';
	//#endregion Commerce

	const breakpointDimensionsString = placeholderDetails.placeholder.dataset.breakpointDimensions;

	const responsiveImage = (
		<ResponsiveImage
			fallbackSrc={banner.imageDto?.url}
			responsiveImageUrls={banner.imageDto?.responsiveImageUrls}
			alt={banner.altTag}
			className={classes}
			breakpointDimensions={breakpointDimensionsString}
			testId={`banner-${banner.name}`}
			// We don't use different classes based on whether the image is loaded or not, at least not for banners.
			inactiveClassName={''}
			loadedClassName={''}
		/>
	);

	let elementToAppend: JSX.Element;
	// needed in commerce
	// eslint-disable-next-line
	let elementToAppendIsImage = true;

	// Wrap the banner in an <a> tag if it has a target URL
	if (banner.forwardUrl) {
		elementToAppend = (
			<a href={banner.forwardUrl} target={banner.browserTarget ?? undefined}>
				{responsiveImage}
			</a>
		);
		elementToAppendIsImage = false;
	} else {
		elementToAppend = responsiveImage;
	}

	if (isCarousel) {
		// when rendering banners for carousel render them as display:none
		// because slider first needs to do the sizing and then it will make them visible
		htmlBanner.style.display = 'none';
	}
	//#region Commerce
	if (isMixedBannerGroup) {
		const classNames = classnames('roc-product-list__image-wrapper', {
			'roc-product-list__image-h-100': elementToAppendIsImage,
		});

		elementToAppend = <div className={classNames}>{elementToAppend}</div>;
	}
	//#endregion Commerce

	// Render the ResponsiveImage to HTML, probably over 90% of this is just static HTML anyways, and we don't use the react-specific features for banners
	const staticElementToAppend = ReactDOMServer.renderToStaticMarkup(elementToAppend);
	htmlBanner.innerHTML = staticElementToAppend;
}
