import React from 'react';
import ResponsiveImage from 'shared/assets/ResponsiveImage';
import ProductPricing from 'shared/components/commerce/ProductPricing';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import { url } from 'util/Urls';
import { toKebabCase } from '../../../util/StringUtil';
import { Banner } from '../../types/banners';

interface ProductBannerProps {
	banner: Banner;
	breakpointDimensions?: string;
	isProductBannerGroup: boolean;
	isMixedBannerGroup: boolean;
}

export function ProductBanner(props: ProductBannerProps) {
	const { banner, isProductBannerGroup, isMixedBannerGroup } = props;

	const product = banner?.product;
	const displayPrice = banner?.price;
	const mainImageUrl = url(banner?.imageDto?.url || '/');
	const mainImageAltText = banner?.altTag;

	let customUrl: string = '#';

	if (product?.url) {
		customUrl = product.url;
	}

	const handleDispatchProductClickedEvent = () => {
		// No tracking info, so do nothing
		if (!banner.productTracking) {
			return;
		}

		const { itemListId, itemListName, productTracking } = banner.productTracking;

		dispatchRocEvent('product-clicked', {
			listId: itemListId,
			listName: itemListName,
			productTracking: productTracking,
			url: customUrl,
		});
	};

	return (
		<>
			{isProductBannerGroup || isMixedBannerGroup ? (
				<div className="roc-product-list__item">
					<a
						href={url(customUrl)}
						className="roc-product-list__link"
						onClick={handleDispatchProductClickedEvent}
					>
						<div className="roc-product-list__image-wrapper">
							<ResponsiveImage
								fallbackSrc={mainImageUrl}
								className="roc-product-list__image"
								alt={mainImageAltText}
								breakpointDimensions={props.breakpointDimensions}
								responsiveImageUrls={banner.imageDto?.responsiveImageUrls}
								lazyLoaded
								testId={`banner-${banner.name}`}
							/>
						</div>
						{product ? (
							<h2
								className="roc-product-list__title"
								data-testid={`rvp-header-${toKebabCase(product.name)}`}
							>
								{product.name}
							</h2>
						) : null}

						<ProductPricing displayPrice={displayPrice} className="roc-product-list" />
					</a>
				</div>
			) : (
				<div className="roc-product-list__item">
					<a
						href={url(customUrl)}
						className="roc-product-list__link"
						onClick={handleDispatchProductClickedEvent}
					>
						<div className="roc-product-list__image-wrapper">
							<ResponsiveImage
								fallbackSrc={mainImageUrl}
								alt={mainImageAltText}
								className={'roc-product-list__image roc-lazy-image'}
								breakpointDimensions={props.breakpointDimensions}
								responsiveImageUrls={banner.imageDto?.responsiveImageUrls}
								lazyLoaded
								testId={`banner-${banner.name}`}
							/>
						</div>

						{product && product.name ? (
							<h2
								className="roc-product-list__title"
								data-testid={`rvp-header-${toKebabCase(product.name)}`}
							>
								{product.name}
							</h2>
						) : null}

						{displayPrice ? (
							<ProductPricing displayPrice={displayPrice} className="roc-product-list" />
						) : null}
					</a>
				</div>
			)}
		</>
	);
}
