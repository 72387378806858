import React from 'react';
import { initReactApp } from 'shared/util/ApplicationInit';
import { Banner, BannerPlaceholderDetails } from '../../types/banners';
import { ProductBanner } from './ProductBanner';

export default function setupProductBanners(
	id: string,
	banner: Banner,
	placeholderDetails: BannerPlaceholderDetails,
	isProductBannerGroup: boolean,
	isMixedBannerGroup: boolean,
) {
	const breakpointDimensionsString = placeholderDetails.placeholder.dataset.breakpointDimensions;

	const render = initReactApp(
		() => (
			<ProductBanner
				banner={banner}
				breakpointDimensions={breakpointDimensionsString}
				isProductBannerGroup={isProductBannerGroup}
				isMixedBannerGroup={isMixedBannerGroup}
			/>
		),
		id,
	);

	render();

	// optional, use this to enable hot reloading
	if ((module as any).hot) {
		(module as any).hot.accept('./ProductBanner', () => {
			render();
		});
	}
}
