import { useEffect } from 'react';
/**
 *
 * A hook to provide focus to the first invalid input field inside of a form.
 */
export const useFocusOnFormError = (dependencies?: any[]) => {
	const formInputWrapperInvalidClassQuery: string = '.roc-form-input__input-wrapper--invalid';
	const formInputClassQuery: string = '.roc-form-input__input';

	useEffect(() => {
		// Need to ensure that react-final-form has completed rendering
		// it's input field errors in order to grab the invalid inputs
		// by defering this function to only run at the end of the call stack
		const timeoutId = window.setTimeout(() => {
			const formInputWrapperInvalid = document.querySelector(formInputWrapperInvalidClassQuery);

			if (formInputWrapperInvalid === null) {
				return;
			}

			const formInput = formInputWrapperInvalid.querySelector(formInputClassQuery);

			if (formInput === null || !(formInput instanceof HTMLElement)) {
				return;
			}

			formInput.focus();
		}, 0);

		return () => window.clearTimeout(timeoutId);
	}, [dependencies]);
};
