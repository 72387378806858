export const ORGANIZATION_PREFIX = '/organization';
export const ORGANIZATION_OVERVIEW_URL = `${ORGANIZATION_PREFIX}`;
export const ORGANIZATION_USERS_URL = `${ORGANIZATION_PREFIX}/users`;
export const ORGANIZATION_USERS_CREATE_URL = `${ORGANIZATION_USERS_URL}/create`;
export const UPDATE_ORGANIZATION_USER_URL = `${ORGANIZATION_PREFIX}/user`;
export const UPDATE_USER_ORGANIZATION_STATUS_URL = `${UPDATE_ORGANIZATION_USER_URL}/organization-status`;
export const UPDATE_USER_DEFAULT_ORGANIZATION_URL = `/user/organization`;
export const INVALIDATE_USER_ORGANIZATION_NOTIFICATION_CACHE_URL = `${UPDATE_USER_DEFAULT_ORGANIZATION_URL}/cache`;
export const ORGANIZATION_ORDERS_URL = `${ORGANIZATION_PREFIX}/orders`;
export const ORGANIZATION_SHIPTOS_URL = `${ORGANIZATION_PREFIX}/ship-to`;
export const ORGANIZATION_PART_NUMBERS_URL = `${ORGANIZATION_PREFIX}/part-numbers`;
export const ORGANIZATION_ORDER_DETAILS = `${ORGANIZATION_ORDERS_URL}/:orderNo`;
