export default function setupCookieMessage() {
	const cookieAcceptBtn = document.getElementById('cookiePolicyAcceptBtn');
	const cookieMessageContainer = document.getElementById('cookieMessageContainer');
	const handleCookieClick = () => {
		const date = new Date();
		// expire in one year
		date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
		const expires = 'expires=' + date.toUTCString();
		document.cookie = 'cookiePolicyAccepted=yes;' + expires + ';path=/';
		if (cookieAcceptBtn !== null) {
			cookieAcceptBtn.removeEventListener('click', handleCookieClick);
			if (cookieMessageContainer !== null) {
				cookieMessageContainer.remove();
			}
		}
	};
	if (cookieAcceptBtn !== null) {
		cookieAcceptBtn.addEventListener('click', handleCookieClick);
	}
	if (document.body.contains(document.getElementById('featuredPostContainer'))) {
		if (cookieMessageContainer !== null) {
			cookieMessageContainer.style.bottom = '90px';
		}
	}
}
