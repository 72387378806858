import React from 'react';
import { SVGProps } from './SVGProps';

/**
 * Map Pin Ghost SVG
 *
 * @returns
 */
function MapPinGhostSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 27 32" className={'roc-icon ' + className} {...rest}>
			<path d="M13.333 0c7.333 0 13.333 6 13.333 13.333 0 9.867-12.133 18.133-12.533 18.4-0.267 0.133-0.533 0.267-0.8 0.267s-0.533-0.133-0.8-0.267c-0.4-0.267-12.533-8.533-12.533-18.4 0-7.333 6-13.333 13.333-13.333zM13.333 29.067c2.533-1.867 10.667-8.533 10.667-15.733 0-5.867-4.8-10.667-10.667-10.667s-10.667 4.8-10.667 10.667c0 7.2 8.133 13.867 10.667 15.733zM13.333 8c2.933 0 5.333 2.4 5.333 5.333s-2.4 5.333-5.333 5.333c-2.933 0-5.333-2.4-5.333-5.333s2.4-5.333 5.333-5.333zM13.333 16c1.467 0 2.667-1.2 2.667-2.667s-1.2-2.667-2.667-2.667c-1.467 0-2.667 1.2-2.667 2.667s1.2 2.667 2.667 2.667z" />
		</svg>
	);
}

MapPinGhostSVG.defaultProps = { className: '' };

export default MapPinGhostSVG;
