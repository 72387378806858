import React from 'react';
import { ModelStatus } from '../../../../Roc.SharedClientApp/types/model-status';
import DashCircleSVG from './hawksearch/components/svg/DashCircleSVG';
import CheckCircleSVG from './svg/CheckCircleSVG';
import FolderSVG from './svg/FolderSVG';
import GarbageCanCircleSVG from './svg/GarbageCanCircleSVG';
import PencilSVG from './svg/PencilSVG';

interface PageEditorStatusDisplayProps {
	status: ModelStatus;
}

export function StatusDisplay(props: PageEditorStatusDisplayProps) {
	const { status } = props;

	return (
		<div className="roc-page-editor-status-display">
			{status === ModelStatus.Published ? (
				<CheckCircleSVG />
			) : status === ModelStatus.Inactive ? (
				<DashCircleSVG />
			) : status === ModelStatus.Deleted ? (
				<GarbageCanCircleSVG />
			) : status === ModelStatus.Draft ? (
				<PencilSVG />
			) : status === ModelStatus.Archived ? (
				<FolderSVG />
			) : null}
		</div>
	);
}
