import React from 'react';
import XSVG from './svg/XSVG';
import { Localizer } from 'services/Localizer';
import { WithTestIdOptional } from '../../../../Roc.SharedClientApp/types/test';

interface CircleButtonProps extends WithTestIdOptional {
	icon?: React.ReactNode;
}

function CircleButton(props: React.HTMLProps<HTMLButtonElement> & CircleButtonProps) {
	const { icon, testId, ...otherProps } = props;

	return (
		<button {...otherProps} type="button" className="roc-circle-button" data-testid={testId}>
			{icon ? icon : <XSVG className="roc-circle-button__icon" />}
			<span className="roc-visually-hidden">{Localizer('Remove')}</span>
		</button>
	);
}

export default CircleButton;
