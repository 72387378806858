import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Globe SVG
 *
 * @returns
 */
function GlobeSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 28 28"
			className={classnames('roc-icon roc-icon-globe', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M13.994 2.333c-6.446 0-11.661 5.221-11.661 11.667s5.215 11.667 11.661 11.667c6.446 0 11.672-5.221 11.672-11.667s-5.227-11.667-11.673-11.667zM22.073 9.333h-3.442c-0.379-1.458-0.91-2.858-1.61-4.153 2.147 0.735 3.932 2.223 5.052 4.153zM14 4.707c0.974 1.4 1.732 2.958 2.228 4.626h-4.457c0.496-1.668 1.254-3.226 2.228-4.626zM4.97 16.333c-0.192-0.747-0.303-1.528-0.303-2.333s0.111-1.587 0.303-2.333h3.937c-0.093 0.764-0.157 1.54-0.157 2.333s0.064 1.569 0.163 2.333h-3.943zM5.921 18.667h3.442c0.379 1.458 0.91 2.858 1.61 4.159-2.147-0.735-3.932-2.228-5.052-4.159zM9.362 9.333h-3.442c1.12-1.931 2.905-3.424 5.052-4.159-0.7 1.301-1.231 2.701-1.61 4.159zM14 23.293c-0.968-1.4-1.727-2.957-2.228-4.626h4.457c-0.502 1.668-1.26 3.226-2.228 4.626zM16.73 16.333h-5.46c-0.111-0.764-0.187-1.54-0.187-2.333s0.076-1.569 0.187-2.333h5.46c0.111 0.764 0.187 1.54 0.187 2.333s-0.076 1.569-0.187 2.333zM17.027 22.82c0.7-1.301 1.231-2.695 1.61-4.153h3.442c-1.126 1.931-2.911 3.418-5.052 4.153zM19.087 16.333c0.093-0.764 0.163-1.54 0.163-2.333s-0.064-1.569-0.163-2.333h3.938c0.192 0.747 0.309 1.528 0.309 2.333s-0.111 1.587-0.309 2.333h-3.938z"></path>
		</svg>
	);
}

export default GlobeSVG;
