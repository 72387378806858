import * as React from 'react';
import { ValidationMessages, FormFieldProps } from '.';
import { FieldProps, Field, FieldRenderProps } from 'react-final-form';
import FieldLabel from './FieldLabel';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import { toKebabCase } from 'util/StringUtil';
import classnames from 'classnames';

export type TextAreaFieldProps = FieldProps<string, FieldRenderProps<string, HTMLElement>> &
	FormFieldProps & {
		rows?: number;
		placeholder?: string;
		maxLength?: number;
	};

/**
 * Component that renders a textarea input component.
 */
export default function TextAreaField({
	name,
	isRequired = true,
	readOnly = false,
	disabled = false,
	placeholder = '',
	label = '',
	rows = 5,
	validate,
	afterSubmit,
	allowNull,
	beforeSubmit,
	component,
	defaultValue,
	format,
	formatOnBlur,
	initialValue,
	isEqual,
	parse,
	render,
	subscription,
	value,
	testId,
	maxLength,
}: TextAreaFieldProps) {
	return (
		<Field
			name={name}
			validate={validate}
			afterSubmit={afterSubmit}
			allowNull={allowNull}
			beforeSubmit={beforeSubmit}
			component={component}
			defaultValue={defaultValue}
			format={format}
			formatOnBlur={formatOnBlur}
			initialValue={initialValue}
			isEqual={isEqual}
			parse={parse}
			render={render}
			subscription={subscription}
			value={value}
		>
			{({ input, meta }) => (
				<div className={meta.dirty ? 'roc-form-group roc-form-group--dirty' : 'roc-form-group'}>
					<FieldLabel label={label} name={name} isRequired={isRequired} htmlFor={name} />
					<FieldInputErrorWrapper name={name}>
						<textarea
							className={classnames('roc-form-input__input', {
								'roc-form-input__input--no-min-height': rows,
							})}
							id={name}
							name={name}
							value={input.value}
							onChange={input.onChange}
							onBlur={input.onBlur}
							onFocus={input.onFocus}
							disabled={disabled}
							readOnly={readOnly}
							placeholder={placeholder}
							rows={rows}
							aria-describedby={name + '-invalid'}
							data-testid={testId || `field-${toKebabCase(name)}`}
							maxLength={maxLength}
						/>
					</FieldInputErrorWrapper>
					<ValidationMessages id={name + '-invalid'} meta={meta} />
				</div>
			)}
		</Field>
	);
}
