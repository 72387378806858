import React from 'react';
import { Localizer } from 'services/Localizer';

/**
 * Reusable component for the optional field marker
 */
function OptionalFieldMarker() {
	return <span className="roc-form-input-required-label">({Localizer('Optional')})</span>;
}

export default React.memo(OptionalFieldMarker);
