import debounce from 'lodash-es/debounce';

// String class definitions
const navToggleButtonClassQuery: string = '.roc-main-nav__toggle-btn';
const navToggleButtonClassExpandedClass: string = 'roc-main-nav__toggle-btn--expanded';
const navListClassQuery: string = '.roc-main-nav__list';
const navListExpandedClass: string = 'roc-main-nav__list--expanded';
const subNavToggleButtonClassQuery: string = '.roc-main-nav__sub-menu-toggle';
const subNavToggleButtonExpandedClass: string = 'roc-main-nav__sub-menu-toggle--expanded';
const subNavExpandedClass: string = 'roc-main-nav__sub-menu--expanded';

/**
 * Check if input parent element contains input child element.
 *
 * @param {*} parent
 * @param {*} child
 * @returns
 */
function isDescendant(parent: HTMLElement, child: HTMLElement) {
	let node = child.parentNode;
	while (node !== null) {
		if (node === parent) {
			return true;
		}
		node = node.parentNode;
	}
	return false;
}

/**
 * The type for each submenu. Each object contains the toggle button, submenu element, and the open state.
 *
 * @interface SubMenu
 */
interface SubMenu {
	/**
	 * The toggle element that triggers the visibility of the [subMenuElement].
	 *
	 * @type {HTMLElement}
	 * @memberof SubMenu
	 */
	toggleElement: HTMLElement;

	/**
	 * The menu element that is toggled by the [toggleElement].
	 *
	 * @type {HTMLElement}
	 * @memberof SubMenu
	 */
	subMenuElement: HTMLElement;

	/**
	 * The open state of the [subMenuElement].
	 *
	 * @type {boolean}
	 * @memberof SubMenu
	 */
	isOpen: boolean;
}

/**
 * The type for the class props.
 *
 * @interface AccessibleNavigationProps
 */
interface AccessibleNavigationProps {
	/**
	 * The HTML element of the parent <nav> element.
	 *
	 * @type {HTMLElement}
	 * @memberof AccessibleNavigationProps
	 */
	navElement: HTMLElement;

	/**
	 * The window width where the navigation shifts from desktop to mobile mode.
	 *
	 * @type {number}
	 * @memberof AccessibleNavigationProps
	 */
	mobileBreakpoint: number;
}

/**
 * Class for creating an accessible <nav> DOM component with aria-expand support.
 *
 * @export
 * @class AccessibleNavigation
 */
export default class AccessibleNavigation {
	private _navElement: HTMLElement;
	private _navToggleButton: HTMLButtonElement;
	private _navListElement: HTMLElement;
	private _subNavElements: SubMenu[] = [];
	private _isNavExpanded: boolean;
	private _executeResizeDebounced: any = null;
	private _mobileBreakpoint: number;

	constructor(props: AccessibleNavigationProps) {
		const { navElement, mobileBreakpoint } = props;

		// Enforce `navElement` parameter
		if (!(navElement instanceof HTMLElement)) {
			throw new Error('The required input [navElementID] must be the ID of an HTML element');
		}

		this._navElement = navElement;

		// Grab all toggle buttons
		const subMenuToggleButtons = Array.from(
			this._navElement.querySelectorAll<HTMLElement>(subNavToggleButtonClassQuery),
		);

		// Loop through toggle buttons and create subMenus array
		for (const toggleButton of subMenuToggleButtons) {
			const subMenuElement = toggleButton.parentElement?.querySelector('.roc-main-nav__sub-menu');

			if (subMenuElement === null) {
				console.warn('Every toggle button should have a child menu.');
				continue;
			}

			if (!(subMenuElement instanceof HTMLElement)) {
				throw new Error('A [subMenuElement] must be an HTML element.');
			}

			this._subNavElements.push({ toggleElement: toggleButton, subMenuElement: subMenuElement, isOpen: false });
		}

		// Grab all elements with class [navToggleButtonClass]
		const toggleButtonsQueryResult: HTMLElement[] = Array.from(
			document.querySelectorAll(navToggleButtonClassQuery),
		);

		// Check if there are more than one and if so, throw an error.
		if (toggleButtonsQueryResult.length > 1) {
			throw new Error(
				`There can only be one toggle button with class '${navToggleButtonClassQuery}'. Please remove the duplicates.`,
			);
		}

		// Check if the only one is a button element.
		if (!(toggleButtonsQueryResult[0] instanceof HTMLButtonElement)) {
			throw new Error(`The navigation toggle element must be a <button>.`);
		}

		// Instanciate the property with the result.
		this._navToggleButton = toggleButtonsQueryResult[0];

		// Grab all elements with class [navListClass]
		const navListQueryResult: HTMLElement[] = Array.from(document.querySelectorAll(navListClassQuery));

		// Check if there are more than one and if so, throw an error.
		if (navListQueryResult.length > 1) {
			throw new Error(
				`There can only be one navigation list with class '${navListClassQuery}'. Please remove the duplicates.`,
			);
		}

		// Instanciate the property with the result.
		this._navListElement = navListQueryResult[0];

		// Set the default nav expanded property to false;
		this._isNavExpanded = false;

		// Instanciate the debounced resize method.
		this._executeResizeDebounced = debounce(this._resize, 10);

		// Instanciate mobile breakpoint.
		this._mobileBreakpoint = mobileBreakpoint;

		this._init();
	}

	private _init() {
		this._setupClickEvents();
		this._setupOutsideclick();
		this._setupEscKeyPress();
		this._setupResizeListener();
		this._setupNavToggleClick();
	}

	/**
	 * Create simple resize event listener.
	 *
	 * @private
	 * @memberof AccessibleNavigation
	 */
	private _setupResizeListener() {
		window.addEventListener('resize', this._handleResize);
	}

	/**
	 * Calls a debounced resize;
	 *
	 * @memberof ComponentsDemoNav
	 */
	private _handleResize = () => {
		this._executeResizeDebounced();
	};

	/**
	 * Houses all resize logic.
	 *
	 * @private
	 * @memberof AccessibleNavigation
	 */
	private _resize() {
		if (window.innerWidth > this._mobileBreakpoint) {
			if (this._navListElement.classList.contains(navListExpandedClass)) {
				this._isNavExpanded = false;
				this._navToggleButton.setAttribute('aria-expanded', 'false');
				this._navToggleButton.classList.remove(navToggleButtonClassExpandedClass);
				this._navListElement.classList.remove(navListExpandedClass);
			}
		}
	}

	/**
	 * Setup esc key press handler that closes the navigation.
	 *
	 * @private
	 * @memberof AccessibleNavigation
	 */
	private _setupEscKeyPress() {
		this._navElement.addEventListener('keydown', (e) => {
			if (e.code === 'Escape') {
				this.closeAllMenus();
				this.collapseNavigationMobile();
			}
		});
	}

	/**
	 * Setup logic for clicking the navigation toggle button and toggling visibility of the nav on mobile.
	 *
	 * @private
	 * @memberof AccessibleNavigation
	 */
	private _setupNavToggleClick() {
		this._navToggleButton.addEventListener('click', () => {
			if (this._isNavExpanded) {
				this.collapseNavigationMobile();
			} else {
				this.unCollapseNavigationMobile();
			}
		});
	}

	/**
	 * Collapses the navigation element on mobile.
	 *
	 * @memberof AccessibleNavigation
	 */
	public collapseNavigationMobile() {
		this._isNavExpanded = false;
		this._navToggleButton.setAttribute('aria-expanded', 'false');
		this._navToggleButton.classList.remove(navToggleButtonClassExpandedClass);
		this._navListElement.classList.remove(navListExpandedClass);
	}

	/**
	 * Uncollapses the navigation element on mobile.
	 *
	 * @memberof AccessibleNavigation
	 */
	public unCollapseNavigationMobile() {
		this._isNavExpanded = true;
		this._navToggleButton.setAttribute('aria-expanded', 'true');
		this._navToggleButton.classList.add(navToggleButtonClassExpandedClass);
		this._navListElement.classList.add(navListExpandedClass);
	}

	// #region EFG
	public handleFocusout(subMenuElement: HTMLElement, e: FocusEvent) {
		if (!(e.currentTarget instanceof HTMLElement) || !(e.relatedTarget instanceof HTMLElement)) {
			return;
		}

		if (!e.currentTarget.contains(e.relatedTarget)) {
			this.toggleSubMenu(subMenuElement, false);
		}
	}
	// #endregion

	/**
	 * Create event listeners and attributes for sub menus.
	 *
	 * @private
	 * @memberof AccessibleNavigation
	 */
	private _setupClickEvents() {
		for (const subMenu of this._subNavElements) {
			subMenu.toggleElement.setAttribute('aria-expanded', 'false');

			subMenu.toggleElement.addEventListener('click', () => {
				// Gather all open submenus
				const openSubMenus = this._subNavElements.filter((menu) => {
					return menu.isOpen === true && subMenu.subMenuElement !== menu.subMenuElement;
				});

				// Close all open submenus that are not the menu that was clicked, or its ancestor
				for (const openMenu of openSubMenus) {
					if (!isDescendant(openMenu.subMenuElement, subMenu.subMenuElement)) {
						this.toggleSubMenu(openMenu.subMenuElement, false);
					}
				}

				// Toggle the submenu open/closed
				this.toggleSubMenu(subMenu.subMenuElement, !subMenu.isOpen);
			});

			// #region EFG
			subMenu.subMenuElement.addEventListener('focusout', this.handleFocusout.bind(this, subMenu.subMenuElement));
			// #endregion
		}
	}

	/**
	 * If user clicks outside of the navigation then close all open submenus.
	 *
	 * @private
	 * @memberof AccessibleNavigation
	 */
	private _setupOutsideclick() {
		window.addEventListener('click', (e: MouseEvent) => {
			if (!(e.target instanceof HTMLElement)) {
				return;
			}

			if (!this._navElement.contains(e.target)) {
				this.closeAllMenus();
				this.collapseNavigationMobile();
			}
		});
	}

	/**
	 * Closes all submenus.
	 *
	 * @memberof AccessibleNavigation
	 */
	public closeAllMenus() {
		for (const menu of this._subNavElements) {
			menu.toggleElement.setAttribute('aria-expanded', 'false');
			menu.toggleElement.classList.remove(subNavToggleButtonExpandedClass);
			menu.subMenuElement.classList.remove(subNavExpandedClass);
			menu.isOpen = false;
		}
	}

	/**
	 * Opens the input sub menu.
	 *
	 * @param {SubMenu} subMenuElement
	 * @memberof AccessibleNavigation
	 */
	public toggleSubMenu(subMenuElement: HTMLElement, expand: boolean) {
		if (!(subMenuElement instanceof HTMLElement)) {
			throw new Error('The required input [subMenuElement] must be an HTML element');
		}

		const targetSubMenu = this._subNavElements.find((menu) => {
			return menu.subMenuElement === subMenuElement;
		});

		if (!targetSubMenu) {
			throw new Error('The [subMenuElement] was not found in the menu.');
		}

		if (expand) {
			targetSubMenu.toggleElement.setAttribute('aria-expanded', 'true');
			targetSubMenu.toggleElement.classList.add(subNavToggleButtonExpandedClass);
			targetSubMenu.subMenuElement.classList.add(subNavExpandedClass);
			targetSubMenu.isOpen = true;
		} else {
			targetSubMenu.toggleElement.setAttribute('aria-expanded', 'false');
			targetSubMenu.toggleElement.classList.remove(subNavToggleButtonExpandedClass);
			targetSubMenu.subMenuElement.classList.remove(subNavExpandedClass);
			targetSubMenu.isOpen = false;
		}
	}
}
