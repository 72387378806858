import React from 'react';
import { SVGProps } from './SVGProps';

/**
 * Shopping List SVG
 *
 * @returns
 */
function ShoppingListSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 32 32" className={'roc-icon ' + className} {...rest}>
			<path d="M28 0h-24c-2.205 0-4 1.795-4 4v24c0 2.205 1.795 4 4 4h24c2.205 0 4-1.795 4-4v-24c0-2.205-1.795-4-4-4zM29.333 28c0 0.736-0.599 1.333-1.333 1.333h-24c-0.735 0-1.333-0.597-1.333-1.333v-24c0-0.735 0.599-1.333 1.333-1.333h24c0.735 0 1.333 0.599 1.333 1.333v24z"></path>
			<path d="M9.227 7.493c-0.067-0.173-0.16-0.307-0.28-0.44-0.067-0.053-0.133-0.107-0.2-0.16-0.080-0.053-0.16-0.093-0.24-0.12-0.080-0.040-0.16-0.067-0.24-0.080-0.267-0.053-0.533-0.027-0.773 0.080-0.16 0.067-0.307 0.16-0.44 0.28-0.12 0.133-0.213 0.267-0.28 0.44-0.067 0.16-0.107 0.333-0.107 0.507s0.040 0.347 0.107 0.507c0.067 0.173 0.16 0.307 0.28 0.44 0.253 0.24 0.6 0.387 0.947 0.387 0.080 0 0.173-0.013 0.267-0.027 0.080-0.013 0.16-0.040 0.24-0.080 0.080-0.027 0.16-0.067 0.24-0.12 0.067-0.053 0.133-0.107 0.2-0.16 0.24-0.253 0.387-0.6 0.387-0.947-0-0.173-0.040-0.347-0.107-0.507z"></path>
			<path d="M24 6.667h-10.667c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h10.667c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333z"></path>
			<path d="M9.307 15.733c-0.013-0.080-0.040-0.16-0.080-0.24-0.027-0.080-0.067-0.16-0.12-0.24-0.053-0.067-0.107-0.133-0.16-0.2-0.133-0.12-0.28-0.213-0.44-0.28-0.32-0.133-0.693-0.133-1.013 0-0.16 0.067-0.307 0.16-0.44 0.28-0.053 0.067-0.107 0.133-0.16 0.2-0.053 0.080-0.093 0.16-0.12 0.24-0.040 0.080-0.067 0.16-0.080 0.24-0.013 0.093-0.027 0.187-0.027 0.267 0 0.347 0.147 0.693 0.387 0.947 0.253 0.24 0.6 0.387 0.947 0.387s0.693-0.147 0.947-0.387c0.24-0.253 0.387-0.6 0.387-0.947-0-0.080-0.013-0.173-0.027-0.267z"></path>
			<path d="M24 14.667h-10.667c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h10.667c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333z"></path>
			<path d="M9.227 23.493c-0.067-0.173-0.16-0.307-0.28-0.44-0.373-0.361-0.96-0.48-1.453-0.28-0.16 0.067-0.307 0.16-0.44 0.28-0.12 0.133-0.213 0.28-0.28 0.44s-0.107 0.333-0.107 0.507c0 0.36 0.133 0.693 0.387 0.947 0.253 0.252 0.587 0.387 0.947 0.387s0.693-0.135 0.947-0.387c0.253-0.253 0.387-0.587 0.387-0.947-0-0.173-0.027-0.347-0.107-0.507z"></path>
			<path d="M24 22.667h-10.667c-0.736 0-1.333 0.596-1.333 1.333s0.597 1.333 1.333 1.333h10.667c0.736 0 1.333-0.596 1.333-1.333s-0.597-1.333-1.333-1.333z"></path>
		</svg>
	);
}

ShoppingListSVG.defaultProps = { className: '' };

export default ShoppingListSVG;
