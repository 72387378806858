import { HeaderUtilNavItemPopover } from 'features/header/HeaderUtilNav';
import React, { Suspense, useState } from 'react';
import Modal from 'shared/components/Modal';
import Spinner from 'shared/components/Spinner';
import MapPinGhostSVG from 'shared/components/svg/MapPinGhostSVG';
import StoreDetailsButton from '../store-details/StoreDetailsButton';
import StoreLocationFormattedAddress from '../store-locator/StoreLocationFormattedAddress';
import StoreLocatorContext from '../store-locator/StoreLocatorContext';
import StoreLocatorSearchForm from '../store-locator/StoreLocatorSearchForm';
import { useStoreLocator } from '../store-locator/useStoreLocator';
import { useDefaultStoreLocationLoader } from './useDefaultStoreLocationLoader';
import { Localizer } from 'services/Localizer';
import { url } from 'util/Urls';
import { ServerData } from 'services/ServerData';

const StoreLocatorModal = React.lazy(() => import(/* webpackChunkName: "store-locator-modal" */ './StoreLocatorModal'));
const headerUtilsIconClass = 'roc-utlity-nav__menu-item-icon';

export default function DefaultStoreLocation() {
	const [defaultStorePopoverOpen, setDefaultStorePopoverOpen] = useState<boolean>(false);
	const [storeLocatorModalOpen, setStoreLocatorModalOpen] = useState<boolean>(false);
	const { defaultStoreLocation, isLoading: isLoadingDefaultStoreLocation } = useDefaultStoreLocationLoader();

	const { state, actor } = useStoreLocator({
		defaultStoreLocation,
		initSearchText: '',
	});

	if (isLoadingDefaultStoreLocation) {
		return <Spinner block />;
	}

	return (
		<StoreLocatorContext.Provider value={{ state, actor }}>
			{defaultStoreLocation ? (
				<HeaderUtilNavItemPopover
					text={defaultStoreLocation.name}
					icon={<MapPinGhostSVG className={headerUtilsIconClass} />}
					isOpen={defaultStorePopoverOpen}
					openPopover={() => setDefaultStorePopoverOpen(true)}
					closePopover={() => setDefaultStorePopoverOpen(false)}
					testId="default-store"
				>
					<div className="roc-store-locator-header-popup">
						<StoreLocationFormattedAddress location={defaultStoreLocation} />

						<div className="roc-flags">
							<div className="roc-flags__item" data-testid="my-store-flag">
								{Localizer('MyStore')}
							</div>
						</div>

						{defaultStoreLocation.url && (
							<div className="roc-store-locator-header-popup__store-btn">
								<StoreDetailsButton url={defaultStoreLocation.url} searchText={state.searchText} link />
							</div>
						)}

						<div>
							{ServerData.EnableGoogleGeocoding ? (
								<button
									type="button"
									className="roc-btn roc-btn--block roc-btn--flush"
									onClick={() => {
										setStoreLocatorModalOpen(true);
										setDefaultStorePopoverOpen(false);
									}}
								>
									{Localizer('FindAnotherStore')}
								</button>
							) : (
								<a
									className="roc-btn roc-btn--block roc-btn--flush"
									href="/store-locator"
									data-testid="modal-store-locator-show-all-input-link"
								>
									{Localizer('ViewAllStores')}
								</a>
							)}
						</div>
					</div>
				</HeaderUtilNavItemPopover>
			) : (
				<HeaderUtilNavItemPopover
					text={Localizer('FindAStore')}
					title={Localizer('FindAStore')}
					icon={<MapPinGhostSVG className={headerUtilsIconClass} />}
					isOpen={defaultStorePopoverOpen}
					openPopover={() => setDefaultStorePopoverOpen(true)}
					closePopover={() => setDefaultStorePopoverOpen(false)}
					size="sm"
					testId="find-a-store"
				>
					{ServerData.EnableGoogleGeocoding ? (
						<StoreLocatorSearchForm
							onSearch={(value: string) => {
								actor.setSearchText(value);
								actor.setDataLoaderActive(true);
								setStoreLocatorModalOpen(true);
								setDefaultStorePopoverOpen(false);
							}}
							isEnabled
							className="roc-store-locator-modal-search-form--search-form-header"
						/>
					) : null}
					<div className="roc-store-locator-modal-search-form__footer">
						<a
							className="roc-store-locator-modal-search-form__footer-link"
							href={url('/store-locator')}
							data-testid="modal-store-locator-show-all-input-link"
						>
							{Localizer('ViewAllStores')}
						</a>
					</div>
				</HeaderUtilNavItemPopover>
			)}

			<Modal
				isOpen={storeLocatorModalOpen}
				closeModal={() => setStoreLocatorModalOpen(false)}
				renderTitle={() => Localizer('FindAStore')}
			>
				<Suspense fallback={<Spinner />}>
					<StoreLocatorModal defaultStoreLocation={defaultStoreLocation} initSearchText={state.searchText} />
				</Suspense>
			</Modal>
		</StoreLocatorContext.Provider>
	);
}
