import React from 'react';
import classnames from 'classnames';
import { useField } from 'react-final-form';

interface FieldGroupProps {
	children: React.ReactNode;
	name: string;
	wrapperClassName?: string;
}

/** Reusable form field group wrapper */
export default function FieldGroup(props: FieldGroupProps) {
	const { children, name, wrapperClassName } = props;
	const { meta } = useField(name);
	const invalid =
		(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit && !meta.submitting)) &&
		meta.touched &&
		meta.invalid
			? meta.invalid
			: false;
	return (
		<div
			className={classnames(
				'',
				{
					'roc-form-group roc-form-group--dirty': meta.dirty,
					'roc-form-group': !meta.dirty,
					[`${wrapperClassName}`]: wrapperClassName,
				},
				{
					'roc-form-input__input-wrapper--invalid': invalid,
				},
			)}
		>
			{children}
		</div>
	);
}
