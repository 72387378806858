import throttle from 'lodash-es/throttle';

export default function setupUserType() {
	// Create html element constant
	const htmlEl = document.documentElement;

	// Temporary boolean that stores whether the touch user was recently added
	let isRecentTouchUser = false;

	// If element can be found
	if (htmlEl) {
		// Add a user bind init class to HTML element if it doesn't have it
		if (!htmlEl.classList.contains('js-user-bind-init')) {
			htmlEl.classList.add('js-user-bind-init');
		}

		// If keyboard usage detected then add keyboard class and remove visual class
		window.addEventListener(
			'keyup',
			throttle(() => {
				if (!htmlEl.classList.contains('js-keyboard-user')) {
					htmlEl.classList.remove('js-mouse-user');
					htmlEl.classList.remove('js-touch-user');
					htmlEl.classList.add('js-keyboard-user');
				}
			}, 500),
		);

		// If visual click usage detected then add visual click class and remove keyboard class
		window.addEventListener(
			'mouseup',
			throttle(() => {
				if (!isRecentTouchUser) {
					if (!htmlEl.classList.contains('js-mouse-user')) {
						htmlEl.classList.remove('js-keyboard-user');
						htmlEl.classList.remove('js-touch-user');
						htmlEl.classList.add('js-mouse-user');
					}
				}
			}, 500),
		);

		// If visual click usage detected then add visual click class and remove keyboard class
		window.addEventListener(
			'touchend',
			throttle(() => {
				if (!htmlEl.classList.contains('js-touch-user')) {
					htmlEl.classList.remove('js-keyboard-user');
					htmlEl.classList.remove('js-mouse-user');
					htmlEl.classList.add('js-touch-user');
				}

				isRecentTouchUser = true;

				setTimeout(() => {
					isRecentTouchUser = false;
				}, 300);
			}, 500),
		);
	}
}
