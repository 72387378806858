import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { useLoader } from './useLoader';
import { useRef, useEffect, useState } from 'react';
import AxiosHelper from 'services/AxiosHelper';

/**
 * Use Axios Loader Params
 * @param axiosParams The parameters for the request being passed into axios. This includes the method, url, data, etc.
 * @param callback A function that is called after a form is posted. It'll include a parameter with the error if something went wrong.
 */
export interface UseAxiosLoaderParams {
	axiosParams: AxiosRequestConfig;
	active?: boolean;
	callback?: (error: Error | AxiosError | undefined) => any;
	cacheKey?: string;
}

const cache = {};

/**
 * A reusable hook that facilitates executing axios requests.
 */
export function useAxiosLoader<T>({ axiosParams, active = true, callback, cacheKey }: UseAxiosLoaderParams) {
	if (axiosParams.method === undefined) {
		axiosParams.method = 'GET';
	}

	const { ...rest } = axiosParams;
	const { loading, setLoading, error, setError, response, setResponse } = useLoader<AxiosResponse<T>>();
	const [reloadTimes, setReloadTimes] = useState<number>(0);
	const cancelToken = useRef<CancelTokenSource>();

	const reload = () => {
		setReloadTimes(reloadTimes + 1);
	};

	useEffect(() => {
		(async () => {
			try {
				if (!active) {
					return;
				}

				cancelToken.current = Axios.CancelToken.source();

				setLoading(true);
				setError(undefined);

				let response: AxiosResponse<T>;

				if (cacheKey && cache[cacheKey]) {
					response = cache[cacheKey];
				} else {
					response = await AxiosHelper(rest);
					if (cacheKey) {
						cache[cacheKey] = response;
					}
				}

				setResponse(response);

				if (callback !== undefined) {
					return callback(undefined);
				}
			} catch (error) {
				if (Axios.isCancel(error)) {
					// if the component was unmounted, there's nothing to do
					return;
				}

				setError(error);

				if (callback !== undefined) {
					return callback(error);
				}
			} finally {
				setLoading(false);
			}
		})();

		return () => {
			// cleanup
			if (cancelToken.current) {
				cancelToken.current.cancel();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active, reloadTimes, ...Object.values(axiosParams)]); // this may be overkill? this means that if ANY axios params change, a new request will execute

	return {
		loading,
		error,
		response,
		cancelToken: cancelToken.current,
		reload,
	};
}
