import React from 'react';
import { initReactAppByAttribute } from 'shared/util/ApplicationInit';
import Announcements, { AnnouncementsProps } from './Announcements';

export default function setupAnnouncements() {
	const render = initReactAppByAttribute(
		(props: AnnouncementsProps) => <Announcements {...props} />,
		'data-roc-announcement',
	);

	render();

	// optional, use this to enable hot reloading
	if ((module as any).hot) {
		(module as any).hot.accept('./Announcements', () => {
			render();
		});
	}
}
