import React from 'react';
import { FieldRenderProps } from 'react-final-form';

type ValidationMessagesProps = Pick<FieldRenderProps<any, HTMLElement>, 'meta'> & {
	id: string;
};

function getErrorArray(errorOrSubmitError) {
	let errors: string[] = [];
	if (typeof errorOrSubmitError === 'string') {
		errors.push(errorOrSubmitError);
	} else if (Array.isArray(errorOrSubmitError)) {
		errors = errorOrSubmitError.filter((error) => !Array.isArray(error) && typeof error === 'string');
	}

	return errors;
}

/**
 * Displays validation errors for fields
 *
 * @class ValidationMessages
 * @extends {React.Component<ValidationMessagesProps>}
 */
function ValidationMessages(props: ValidationMessagesProps) {
	const { meta } = props;

	if (!meta) {
		return null;
	}

	let errors: string[] = [];

	/* 
	 almost all validation comes from the server, so we only need to check `submitError` despite there being `error`
	 there are 3 kinds of values for submitError, string, string[] and [string|[]|object]
	 the third type happens when you're using FieldArray, so we're going to filter those out and only
	 take the strings if submitError is an array. this is due to a bug (possibly feature?) of final form
	 where the submit errors for field array fields bubble up into the parent field.
	 */
	if (meta.submitError) {
		errors = errors.concat(getErrorArray(meta.submitError));

		// for server-side validation, we need to stop showing these after any changes are made.
		if (meta.dirtySinceLastSubmit || meta.submitting) {
			return null;
		}
	} else if (meta.error) {
		// sometimes we validate on the client, so we need to support these as well.
		errors = errors.concat(getErrorArray(meta.error));
	}

	return (
		<span className="roc-form-suffix roc-form-suffix--error" id={props.id} data-testid={props.id}>
			{(meta.submitError || meta.error) &&
				meta.touched &&
				errors.length > 0 &&
				errors.map((error, index) => (
					<React.Fragment key={index}>
						<span>{error}</span>
						<br />
					</React.Fragment>
				))}
		</span>
	);
}

export default ValidationMessages;
