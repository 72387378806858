export default function setupRelatedProducts() {
	const elements = document.querySelectorAll('[data-roc-related-products]');

	if (elements.length === 0) {
		console.warn(`No elements marked to display related products were found.`);
	} else {
		const tnsPromise = import('tiny-slider/src/tiny-slider').then((module) => module.tns);

		(async () => {
			const tns = await tnsPromise;

			elements.forEach((element) => {
				tns({
					container: element,
					loop: false,
					items: 1,
					slideBy: 'page',
					controls: false,
					mouseDrag: true,
					gutter: 15,
					lazyload: true,
					responsive: {
						622: {
							items: 2,
						},
						992: {
							items: 3,
						},
						1200: {
							items: 5,
						},
					},
				});
			});
		})();
	}
}
