import React, { useState, useEffect } from 'react';
import type PopoverType from '@reach/popover';
import classnames from 'classnames';
import type { Position } from '@reach/popover';
import Spinner from './Spinner';

const Popover = React.lazy<typeof PopoverType>(() => import(/* webpackChunkName: "reach-popover" */ '@reach/popover'));

export type RocPopoverSize = 'xs' | 'sm' | 'md' | 'lg';

interface RocPopoverProps {
	innerRef: React.MutableRefObject<HTMLDivElement | null>;
	targetRef: React.MutableRefObject<HTMLButtonElement | null>;
	closePopover: () => void;
	position: Position;
	title?: string;
	size?: RocPopoverSize;
	children: React.ReactNode;
	className?: string;
}

/**
 * Reusable popover component for displaying a ReachUI popover with sizing options.
 *
 * @export
 * @param {RocPopoverProps} props
 * @returns
 */
export default function RocPopover(props: RocPopoverProps) {
	const { innerRef, targetRef, position, closePopover, title, size = 'sm', children, className = '' } = props;
	const [space, setSpace] = useState<number>(0);

	useEffect(() => {
		if (!targetRef || !targetRef.current) {
			return;
		}

		setSpace(window.innerHeight - targetRef.current.getBoundingClientRect().bottom);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleDocumentClick, false);

		return () => {
			document.removeEventListener('mousedown', handleDocumentClick, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * If you click outside the popoover when its open, close the popover.
	 *
	 * @param {*} e
	 * @returns
	 */
	function handleDocumentClick(e) {
		if (innerRef.current === null || targetRef.current === null) {
			return;
		}

		// If click is inside popover or trigger, return.
		if (
			targetRef.current === e.target ||
			targetRef.current.contains(e.target) ||
			innerRef.current === e.target ||
			innerRef.current.contains(e.target)
		) {
			return;
		}

		closePopover();
	}

	return (
		<React.Suspense fallback={<Spinner />}>
			<Popover ref={innerRef} targetRef={targetRef} position={position} as={'div'}>
				<div
					className={classnames('roc-popover-body', {
						'roc-popover-body--xs': size === 'xs',
						'roc-popover-body--sm': size === 'sm',
						'roc-popover-body--md': size === 'md',
						'roc-popover-body--lg': size === 'lg',
						'roc-popover-body--top': space < 350,
						[className]: className,
					})}
				>
					{title ? <h2 className="roc-popover__title">{title}</h2> : null}
					<div className="roc-popover-content">{children}</div>
				</div>
			</Popover>
		</React.Suspense>
	);
}
