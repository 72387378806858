import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Cart Down Arrow SVG Icon
 *
 * @returns
 */
function CartDownArrowSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 26 28" className={classnames('roc-icon roc-icon-cart-down-arrow', className)} {...rest}>
			<path d="M20 11c0-0.547-0.453-1-1-1-0.266 0-0.516 0.109-0.703 0.297l-2.297 2.281v-4.578c0-0.547-0.453-1-1-1s-1 0.453-1 1v4.578l-2.297-2.281c-0.187-0.187-0.438-0.297-0.703-0.297-0.547 0-1 0.453-1 1 0 0.266 0.109 0.516 0.297 0.703l4 4c0.187 0.187 0.438 0.297 0.703 0.297s0.516-0.109 0.703-0.297l4-4c0.187-0.187 0.297-0.438 0.297-0.703zM10 24c0 1.109-0.891 2-2 2s-2-0.891-2-2 0.891-2 2-2 2 0.891 2 2zM24 24c0 1.109-0.891 2-2 2s-2-0.891-2-2 0.891-2 2-2 2 0.891 2 2zM26 7v8c0 0.5-0.375 0.938-0.891 1l-16.312 1.906c0.063 0.344 0.203 0.734 0.203 1.094s-0.219 0.688-0.375 1h14.375c0.547 0 1 0.453 1 1s-0.453 1-1 1h-16c-0.547 0-1-0.453-1-1 0-0.484 0.734-1.687 0.953-2.141l-2.766-12.859h-3.187c-0.547 0-1-0.453-1-1s0.453-1 1-1h4c1.062 0 1.078 1.25 1.234 2h18.766c0.547 0 1 0.453 1 1z"></path>
		</svg>
	);
}

export default CartDownArrowSVG;
