import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Clock SVG
 *
 * @returns
 */
function ClockSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('roc-icon roc-clock', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M16 0.594c8.543 0 15.406 6.863 15.406 15.406s-6.863 15.406-15.406 15.406c-8.543 0-15.406-6.863-15.406-15.406s6.863-15.406 15.406-15.406zM16 28.605c7.003 0 12.605-5.602 12.605-12.605s-5.602-12.605-12.605-12.605c-7.003 0-12.605 5.602-12.605 12.605s5.602 12.605 12.605 12.605zM22.162 17.541c0.7 0.42 0.98 1.26 0.7 1.821s-0.7 0.84-1.26 0.84c-0.28 0-0.42 0-0.56-0.14l-5.602-2.801c-0.56-0.28-0.84-0.7-0.84-1.26v-8.403c0-0.84 0.56-1.401 1.401-1.401s1.401 0.56 1.401 1.401v7.563l4.762 2.381z" />
		</svg>
	);
}

export default ClockSVG;
