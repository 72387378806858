import React, { useState } from 'react';
import { Localizer } from 'services/Localizer';
import RocForm from 'shared/edit/RocForm';
import { FormValidationPromise } from 'shared/types';
import AxiosHelper from 'services/AxiosHelper';
import Axios, { AxiosError, CancelTokenSource } from 'axios';
import { handleAxiosErrorWithValidation } from 'shared/util/Misc';
import { ServerData } from 'services/ServerData';
import TextBoxField from 'shared/edit/fields/TextBoxField';
import { useFocusOnFormError } from 'shared/hooks/useFocusOnFormError';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';

interface Dto {
	zipCode: string;
}

interface ValidateZipCodeResponse {
	isValid: boolean;
	isCovered: boolean;
}

function ZipCodeForm() {
	const [zipCode, setZipCode] = useState<string>();
	const [error, setError] = useState<Error | AxiosError | null>(null);

	const onSubmit = async (formValues: Dto): FormValidationPromise<void> => {
		const requestToken: CancelTokenSource = Axios.CancelToken.source();

		try {
			if (formValues?.zipCode == null) {
				const input = document.getElementById('zipCode') as HTMLInputElement;
				if (input && input.value) {
					formValues = { zipCode: input.value };
				}
			}

			const response = await AxiosHelper.post<ValidateZipCodeResponse>('/ajax/zipcode/validate', formValues, {
				cancelToken: requestToken.token,
			});

			const isValid = response?.data?.isValid;
			const isCovered = response?.data?.isCovered;

			if (isValid && isCovered) {
				dispatchRocEvent('plan-selection', { formID: 'lead_zipcode' });
				window.location.href = (ServerData.ShopPlansUrlPage || '') + '?zipcode=' + formValues.zipCode;
			}

			if (isValid && !isCovered) {
				window.location.href = ServerData.NoCoverageUrlPage ?? '/no-coverage';
			}
		} catch (errorr) {
			const axiosError: AxiosError = errorr;
			const serverException: any = axiosError?.response && axiosError?.response?.data;
			axiosError.message = serverException.zipCode[0];
			setError(axiosError);
			return handleAxiosErrorWithValidation(axiosError, Localizer('ZipCodeErrorMessage'));
		}
	};

	useFocusOnFormError([error]);

	return (
		<div className="roc-body-content__row">
			<div className="zip-form-wrap">
				<RocForm<Dto>
					onSubmit={onSubmit}
					initialValues={{ zipCode }}
					render={({ handleSubmit, submitting }) => {
						return (
							<form onSubmit={handleSubmit} className="roc-contact-form">
								<div className="zip-plan-input">
									<TextBoxField
										placeholder="Enter ZIP Code"
										type="text"
										id="zipCode"
										name="zipCode"
										isRequired
										ariaLive="polite"
										onChange={(e) => setZipCode(e.target.value)}
									/>
								</div>
								<div className="zip-plan-button">
									<button
										disabled={submitting}
										type="submit"
										className="roc-btn"
										data-testid="contact-form-submit"
									>
										{Localizer('Step1PickYourPlans')}
									</button>
								</div>
							</form>
						);
					}}
				/>
			</div>
		</div>
	);
}

export default ZipCodeForm;
