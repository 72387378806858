import { headerUtilsIconClass } from 'features/commerce/organization/OrganizationHeaderMenu';
import { HeaderUtilNavItemPopover } from 'features/header/HeaderUtilNav';
import React, { useState } from 'react';
import { Localizer } from 'services/Localizer';
import Spinner from 'shared/components/Spinner';
import MapPinGhostSVG from 'shared/components/svg/MapPinGhostSVG';
import InlineAlert from 'shared/edit/InlineAlert';
import { useShipToPickerLoader } from './useShipToPickerLoader';

export default function HeaderShipToPicker() {
	const [shipToPickerPopoverOpen, setShipToPickerPopoverOpen] = useState<boolean>(false);
	const {
		isLoading,
		selectedShipTo,
		hasMultipleShipTos,
		setSelectedShipToId,
		isSettingSelectedShipTo,
		setLoadShipTos,
		shipTos,
		isLoadingShipTos,
		error,
	} = useShipToPickerLoader();

	if (isLoading) {
		return <Spinner block />;
	}

	const handleOnChange = (e) => {
		setSelectedShipToId(e.target.value);
	};

	return hasMultipleShipTos ? (
		<HeaderUtilNavItemPopover
			text={selectedShipTo?.name ?? Localizer('ShipTosText')}
			title={Localizer('SelectAShipTo')}
			isOpen={shipToPickerPopoverOpen}
			icon={<MapPinGhostSVG className={headerUtilsIconClass} />}
			openPopover={() => {
				setLoadShipTos(true);
				setShipToPickerPopoverOpen(true);
			}}
			closePopover={() => setShipToPickerPopoverOpen(false)}
			testId="ship-to"
			className="ship-to-popover"
		>
			{isLoadingShipTos ? (
				<Spinner block />
			) : (
				<span>
					{error != null ? <InlineAlert error={error} /> : null}
					<div className="roc-form-input__input-wrapper roc-form-input__input-wrapper--inline">
						{/* eslint-disable-next-line */}
						<select
							className="roc-form-input__input"
							value={selectedShipTo?.id}
							onChange={handleOnChange}
							disabled={isSettingSelectedShipTo}
							data-testid="ship-to-selection"
						>
							{selectedShipTo && selectedShipTo.id ? null : <option />}
							{shipTos?.map((st, index) => (
								<option key={index} value={st.id}>
									{st.name}
								</option>
							))}
						</select>
						{isSettingSelectedShipTo ? <Spinner /> : null}
					</div>
				</span>
			)}
		</HeaderUtilNavItemPopover>
	) : (
		<li className="roc-utility-nav__item">
			<div className="roc-utility-nav__item-text roc-utility-nav__item-text--ship-to">
				<span className="roc-utlity-nav__menu-item-icon-wrapper">
					<MapPinGhostSVG className={headerUtilsIconClass} />
				</span>
				<span>{selectedShipTo?.name}</span>
			</div>
		</li>
	);
}
