import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Up chevron SVG
 *
 * @returns
 */
function UpChevronSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 60 32"
			className={classnames('roc-icon roc-icon-up-chevron ', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M30.024 6.44l-25.562 24.482c-0.937 0.92-2.436 0.92-3.373 0-0.446-0.437-0.698-1.036-0.698-1.661s0.252-1.224 0.698-1.661l27.243-26.844c0.936-0.92 2.436-0.92 3.373 0l27.243 26.844c0.447 0.437 0.699 1.036 0.699 1.661s-0.252 1.224-0.699 1.661c-0.937 0.92-2.436 0.92-3.373 0l-25.551-24.482z" />
		</svg>
	);
}

export default UpChevronSVG;
