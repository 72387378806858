import React from 'react';
import { joinTrimmedStrings } from 'util/StringUtil';

const defaultRichTextContentProvider = 'ck-editor';

type richTextContentProvider = 'ck-editor';

interface RichTextContentProps {
	htmlContent: string;
	contentProvider?: richTextContentProvider;
	dataTestId?: string;
	className?: string;
}

/**
 * Component for rendering rich text html content.
 * Returns the content wrapped in container with the appropriate style classes for the html content provider (CK editor or other).
 * @export
 */
export default function RichTextContent(props: RichTextContentProps) {
	const { htmlContent, contentProvider, dataTestId, className } = props;
	const classNameCombined = joinTrimmedStrings(
		' ',
		constructWrapperClassForProvider(contentProvider ?? defaultRichTextContentProvider),
		className,
	);

	return (
		<div className={classNameCombined} data-testid={dataTestId} dangerouslySetInnerHTML={{ __html: htmlContent }} />
	);
}

function constructWrapperClassForProvider(provider?: richTextContentProvider) {
	switch (provider) {
		case 'ck-editor':
			return 'ck-content';
		default:
			return '';
	}
}
