import React from 'react';
import { SVGProps } from '../SVGProps';
import classnames from 'classnames';

/**
 * Users SVG
 *
 * @returns
 */
function UsersSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			className={classnames('roc-icon roc-icon-chat-bubbles ', className)}
			width="1em"
			height="1em"
			viewBox="0 0 45 32"
			{...rest}
		>
			<path d="M22.727 16c4.396 0 7.955-3.558 7.955-7.955s-3.558-7.955-7.955-7.955c-4.396 0-7.955 3.558-7.955 7.955s3.558 7.955 7.955 7.955zM6.818 13.727c2.507 0 4.545-2.038 4.545-4.545s-2.038-4.545-4.545-4.545c-2.507 0-4.545 2.038-4.545 4.545s2.038 4.545 4.545 4.545zM38.636 13.727c2.507 0 4.545-2.038 4.545-4.545s-2.038-4.545-4.545-4.545c-2.507 0-4.545 2.038-4.545 4.545s2.038 4.545 4.545 4.545zM43.182 25.091c1.257 0 2.273-1.016 2.273-2.273v-2.273c0-2.507-2.038-4.545-4.545-4.545h-4.545c-1.25 0-2.379 0.504-3.203 1.321 2.862 1.57 4.893 4.403 5.334 7.77h4.688zM6.953 25.091c0.447-3.366 2.479-6.2 5.341-7.77-0.824-0.817-1.953-1.321-3.203-1.321h-4.545c-2.507 0-4.545 2.038-4.545 4.545v2.273c0 1.257 1.016 2.273 2.273 2.273h4.68zM32.954 31.909c1.882 0 3.409-1.527 3.409-3.409v-2.045c0-4.517-3.665-8.182-8.182-8.182h-0.589c-1.477 0.71-3.118 1.136-4.865 1.136s-3.381-0.426-4.865-1.136h-0.589c-4.517 0-8.182 3.665-8.182 8.182v2.045c0 1.882 1.527 3.409 3.409 3.409h20.454z"></path>
		</svg>
	);
}

export default UsersSVG;
