import React from 'react';
import classnames from 'classnames';
import CircleExclamationSVG from 'shared/components/svg/CircleExclamationSVG';
import { useField } from 'react-final-form';

interface FieldInputErrorWrapperProps {
	hideIcon?: boolean;
	children: React.ReactNode;
	inline?: boolean;
	name: string;
}

/**
 * Component for handling the invalid styles for inputs.
 *
 * @export
 * @param {FieldInputErrorWrapperProps} props
 * @returns
 */
export default function FieldInputErrorWrapper(props: FieldInputErrorWrapperProps) {
	const { hideIcon = false, children, inline = false, name } = props;
	const { meta } = useField(name);
	const invalid =
		(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit && !meta.submitting)) &&
		meta.touched &&
		meta.invalid
			? meta.invalid
			: false;

	return (
		<div
			className={classnames('roc-form-input__input-wrapper', {
				'roc-form-input__input-wrapper--invalid': invalid,
				'roc-form-input__input-wrapper--inline': inline,
			})}
		>
			{children}
			{invalid && !hideIcon ? <CircleExclamationSVG className="roc-form-input__input-wrapper-icon" /> : null}
		</div>
	);
}
