import React from 'react';
import { Localizer } from 'services/Localizer';
import classnames from 'classnames';
import { url } from 'util/Urls';

/**
 * Store Details Component Props
 */
interface StoreDetailsProps {
	url: string | null;
	searchText: string | null;
	link?: boolean;
}

/**
 * Renders a button (link) that points to the store details page.
 * @param props
 */
export default function StoreDetailsButton(props: StoreDetailsProps) {
	const { searchText, link } = props;

	return (
		<a
			className={classnames('', {
				'roc-store-locator-results-item__actions-item roc-btn': !link,
				'roc-btn-link': link,
			})}
			href={url(`${props.url}${searchText ? `?searchText=${searchText}` : ''}`)}
		>
			{Localizer('StoreDetails')}
		</a>
	);
}
