import React, { useContext } from 'react';
import { StoreLocatorState, StoreLocatorActor } from './useStoreLocator';

/**
 * Context for product details
 */
export interface StoreLocatorContext {
	state: StoreLocatorState;
	actor: StoreLocatorActor;
}

const StoreLocatorContext = React.createContext<StoreLocatorContext | null>(null);

export default StoreLocatorContext;

/**
 * Custom hook for store locator context
 */
export function useStoreLocatorContext() {
	const context = useContext(StoreLocatorContext);

	if (context === null) {
		throw new Error('No StoreLocatorContext.Provider found.');
	}

	return context;
}
