import React from 'react';
import HeaderUtils from './HeaderUtils';
import { initReactApp } from 'shared/util/ApplicationInit';

export default function setupHeader() {
	const render = initReactApp(() => <HeaderUtils />, 'header-utils');

	render();

	// optional, use this to enable hot reloading
	if ((module as any).hot) {
		(module as any).hot.accept('./HeaderUtils', () => {
			render();
		});
	}
}
