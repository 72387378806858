import React, { useEffect, useState } from 'react';
import { ServerData } from 'services/ServerData';
import GlobeSVG from 'shared/components/svg/GlobeSVG';
import useRocEventHandler from 'shared/hooks/useRocEventHandler';
import { HeaderUtilNavItemPopover } from './HeaderUtilNav';
import { SiteSwitcherServerData } from './types';

/**
 * Renders links to different sites/hosts based on details provided by the server.
 * By default, the server only returns other hosts for the same site, grouped by culture, using canonical culture hosts.
 */
export function SiteSwitcher() {
	const [siteSwitcherPopoverOpen, setSiteSwitcherPopoverOpen] = useState<boolean>(false);

	const [siteSwitcher, setSiteSwitcher] = useState<SiteSwitcherServerData | null>(null);

	useEffect(() => {
		if (ServerData.SITE_SWITCHER) {
			console.debug('ServerData: ', ServerData.SITE_SWITCHER);
			setSiteSwitcher(ServerData.SITE_SWITCHER);
		}
	}, []);

	useRocEventHandler(
		'site-switcher-updated',
		({ url }) => {
			console.debug('ServerData: ', ServerData.SITE_SWITCHER);
			const siteGroups = ServerData.SITE_SWITCHER?.Groups.map((group) => ({
				...group,
				Hosts: group.Hosts.filter((host) => host.UniqueId !== siteSwitcher?.CurrentHost.UniqueId).map(
					(host) => ({
						...host,
						AbsoluteUrl: host.BaseUrl + url,
					}),
				),
			}));

			if (ServerData.SITE_SWITCHER && siteGroups) {
				setSiteSwitcher({ ...ServerData.SITE_SWITCHER, Groups: siteGroups });
			}
		},
		[],
	);

	if (!ServerData.SITE_SWITCHER) {
		console.warn('Unable to load Site Switcher data.');
		return null;
	}

	if (
		ServerData.SITE_SWITCHER.Groups.length === 0 ||
		ServerData.SITE_SWITCHER.Groups.every((g) => g.Hosts.length <= 1)
	) {
		console.warn('No groups or hosts found. Hiding site switcher');
		return null;
	}

	if (!siteSwitcher) {
		console.warn('siteSwitcher was not set! Hiding site switcher');
		return null;
	}

	return (
		<HeaderUtilNavItemPopover
			isOpen={siteSwitcherPopoverOpen}
			openPopover={() => setSiteSwitcherPopoverOpen(true)}
			closePopover={() => setSiteSwitcherPopoverOpen(false)}
			icon={<GlobeSVG className={'roc-utlity-nav__menu-item-icon roc-utlity-nav__menu-item-icon-small-icon'} />}
			text={siteSwitcher.CurrentHost.Label}
			size="xs"
			testId="site-switcher"
		>
			<ul className="roc-utlity-nav__menu">
				{siteSwitcher.Groups.map((group) =>
					group.Hosts.filter((host) => host.UniqueId !== siteSwitcher?.CurrentHost.UniqueId).map(
						(host, index) => (
							<li className="roc-utlity-nav__menu-item" key={index}>
								<a
									href={host.AbsoluteUrl}
									className="roc-utlity-nav__menu-item-link"
									data-testid="site-switcher-host-link"
								>
									{host.Label}
								</a>
							</li>
						),
					),
				)}
			</ul>
		</HeaderUtilNavItemPopover>
	);
}

export interface SiteSwitcher {
	CurrentHost: SiteSwitcherHost;
	Groups: SiteSwitcherGroup[];
}

export interface SiteSwitcherGroup {
	Hosts: SiteSwitcherHost[];
}

export interface SiteSwitcherHost {
	UniqueId: string;
	BaseUrl: string;
	AbsoluteUrl: string;
	Label: string;
	CultureCode: string;
	CultureId: string;
}
