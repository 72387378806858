import DefaultStoreLocation from 'features/commerce/default-store-location/DefaultStoreLocation';
import HeaderShipToPicker from 'features/commerce/header-ship-to-picker/HeaderShipToPicker';
import OrganizationHeaderMenu from 'features/commerce/organization/OrganizationHeaderMenu';
import {
	MY_ACCOUNT_ADDRESS_BOOK_URL,
	MY_ACCOUNT_BUY_AGAIN_URL,
	MY_ACCOUNT_ORDERS_URL,
	MY_ACCOUNT_PATH_PREFIX,
	MY_ACCOUNT_SAVED_CARTS_URL,
	MY_ACCOUNT_SAVED_FOR_LATER_URL,
	MY_ACCOUNT_SETTINGS_URL,
	MY_ACCOUNT_SHOPPING_LISTS_URL,
	MY_ACCOUNT_STORE_URL,
	MY_ACCOUNT_WALLET_URL,
} from 'features/my-account/routes.constants';
import React, { useEffect, useState } from 'react';
import { Localizer } from 'services/Localizer';
import { getUserData, UserData } from 'services/UserData';
import RenderImpersonationHeader from 'shared/components/RenderImpersonationHeader';
import BookmarkSVG from 'shared/components/svg/BookmarkSVG';
import CartDownArrowSVG from 'shared/components/svg/CartDownArrowSVG';
import CheckClipboardSVG from 'shared/components/svg/CheckClipBoard';
import ClockSVG from 'shared/components/svg/ClockSVG';
import CreditCardSimpleSVG from 'shared/components/svg/CreditCardSimpleSVG';
import GearSVG from 'shared/components/svg/GearSVG';
import HouseSVG from 'shared/components/svg/HouseSVG';
import LockSimpleSVG from 'shared/components/svg/LockSimpleSVG';
import MapPinSVG from 'shared/components/svg/MapPinSVG';
import ShoppingCartPlusSVG from 'shared/components/svg/ShoppingCartPlusSVG';
import ShoppingListSVG from 'shared/components/svg/ShoppingListSVG';
import UserSVG from 'shared/components/svg/UserSVG';
import useRocEventHandler from 'shared/hooks/useRocEventHandler';
import AccountUrls from 'shared/util/AccountUrls';
import { url } from 'util/Urls';
import { ServerData } from '../../services/ServerData';
import { dispatchRocEvent } from '../../shared/hooks/useRocEventHandler';
import HeaderUtilNav, { HeaderUtilNavItemCart, HeaderUtilNavItemLink, HeaderUtilNavItemPopover } from './HeaderUtilNav';
import { SiteSwitcher } from './SiteSwitcher';

const headerUtilsIconClass = 'roc-utlity-nav__menu-item-icon';

/**
 * Component that manages the header utilities, such as login, location, etc.
 *
 * @export
 * @returns
 */
export default function HeaderUtils() {
	const [userData, setUserData] = useState<UserData | null>(null);
	const [myAccountPopoverOpen, setMyAccountPopoverOpen] = useState<boolean>(false);

	// #region Commerce
	const [hideWalletNavigation, setHideWalletNavigation] = useState<boolean>(false);

	useRocEventHandler(
		'roc-cart-summary-updated',
		({ cartSummary }) => {
			setUserData({
				...userData,
				cartSummary,
			});
		},
		[userData],
	);

	const hasShipTos =
		userData?.summary &&
		userData.summary.isSignedIn &&
		userData.summary.organization &&
		(userData.summary.selectedShipTo || userData.summary.hasMultipleShipTos != null);
	// #endregion

	useEffect(() => {
		(async () => {
			try {
				const data = (await getUserData()).data;

				setUserData(data);

				if (data.impersonation != null) {
					RenderImpersonationHeader();
				}

				// #region Commerce
				dispatchRocEvent('page-load', { pageType: ServerData.PAGE_TYPE, cart: data.cartSummary });
				if (data?.summary?.organization) {
					setHideWalletNavigation(
						!data.summary.organization.creditCardAllowed && !data.summary.hasSavedPaymentMethods,
					);
				}
				// #endregion
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
	}, []);

	if (!userData) {
		return null;
	}

	const iconClass = 'roc-utlity-nav__menu-item-link-icon';

	return (
		<HeaderUtilNav>
			{
				// #region Commerce
			}
			{hasShipTos ? <HeaderShipToPicker /> : userData.enableStoreLocation ? <DefaultStoreLocation /> : null}
			{
				// #endregion
			}
			{userData?.summary?.isSignedIn ? (
				<>
					<HeaderUtilNavItemPopover
						isOpen={myAccountPopoverOpen}
						openPopover={() => setMyAccountPopoverOpen(true)}
						closePopover={() => setMyAccountPopoverOpen(false)}
						icon={<UserSVG className={headerUtilsIconClass} />}
						text={Localizer('HeaderWelcomeText', userData.summary.firstName || 'User')}
						title={Localizer('MyAccount')}
						size="xs"
						testId="my-account"
					>
						<ul className="roc-utlity-nav__menu">
							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_PATH_PREFIX)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-account-overview-link"
								>
									<UserSVG className={iconClass} />
									{Localizer('HeaderUtilNavAccountOverview')}
								</a>
							</li>
							{
								// #region Commerce
							}
							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_ORDERS_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-orders-link"
								>
									<ClockSVG className={iconClass} />
									{Localizer('Orders')}
								</a>
							</li>

							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_BUY_AGAIN_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-buy-again-link"
								>
									<CartDownArrowSVG className={iconClass} />
									{Localizer('BuyAgain')}
								</a>
							</li>
							{
								//#endregion Commerce
							}
							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_ADDRESS_BOOK_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-addresses-link"
								>
									<MapPinSVG className={iconClass} />
									{Localizer('AddressBookPageTitleText')}
								</a>
							</li>
							{
								//#region Commerce
							}
							{!hideWalletNavigation ? (
								<>
									{
										//#endregion Commerce
									}
									<li className="roc-utlity-nav__menu-item">
										<a
											href={url(MY_ACCOUNT_WALLET_URL)}
											className=" roc-utlity-nav__menu-item-link"
											data-testid="my-account-popover-wallet-link"
										>
											<CreditCardSimpleSVG className={iconClass} />
											{Localizer('Wallet')}
										</a>
									</li>
									{
										//#region Commerce
									}
								</>
							) : null}

							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_SAVED_FOR_LATER_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-saved-for-later-link"
								>
									<BookmarkSVG className={iconClass} />
									{Localizer('SavedForLater')}
								</a>
							</li>

							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_SAVED_CARTS_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-saved-carts-link"
								>
									<BookmarkSVG className={iconClass} />
									{Localizer('SavedCarts')}
								</a>
							</li>

							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_SHOPPING_LISTS_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-shopping-lists-link"
								>
									<ShoppingListSVG className={iconClass} />
									{Localizer('ShoppingLists')}
								</a>
							</li>

							{userData.enableStoreLocation ? (
								<li className="roc-utlity-nav__menu-item">
									<a
										href={url(MY_ACCOUNT_STORE_URL)}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-store-link"
									>
										<HouseSVG className={iconClass} />
										{Localizer('Store')}
									</a>
								</li>
							) : null}

							{
								// #endregion
							}
							{/* Under Review
								<li className="roc-utlity-nav__menu-item">
									<a
										href={url(MY_ACCOUNT_PERSONAL_DATA_URL)}
										className=" roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-personal-data-link"
									>
										<ShieldUserSVG className={iconClass} />
										{Localizer('HeaderUtilNavPersonalData')}
									</a>
								</li> */}
							<li className="roc-utlity-nav__menu-item">
								<a
									href={url(MY_ACCOUNT_SETTINGS_URL)}
									className="roc-utlity-nav__menu-item-link"
									data-testid="my-account-popover-settings-link"
								>
									<GearSVG className={iconClass} />
									{Localizer('HeaderUtilNavSettings')}
								</a>
							</li>
							<li className="roc-utlity-nav__menu-item">
								<form
									action={`${url(AccountUrls.Logout)}?returnUrl=${encodeURIComponent('/')}`}
									method="post"
									id="logoutForm"
								>
									<button
										type="submit"
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-logout-link"
									>
										<LockSimpleSVG className={iconClass} />
										{Localizer('HeaderUtilNavLogout')}
									</button>
								</form>
							</li>
						</ul>
					</HeaderUtilNavItemPopover>
					{
						// #region Commerce
					}
					<HeaderUtilNavItemLink
						icon={<ClockSVG className={headerUtilsIconClass + ' roc-utlity-nav__menu-item-icon-clock'} />}
						path={MY_ACCOUNT_ORDERS_URL}
						text={Localizer('OrderStatus')}
						testId="orders"
					/>
					{
						// #endregion
					}
				</>
			) : (
				<>
					<HeaderUtilNavItemLink
						icon={<CheckClipboardSVG className={headerUtilsIconClass} />}
						path={AccountUrls.Register}
						text={Localizer('Register')}
						testId="register"
					/>
					<HeaderUtilNavItemLink
						path={AccountUrls.Login}
						text={Localizer('HeaderLoginText')}
						testId="login"
					/>
					{
						// #region Commerce
					}
					<HeaderUtilNavItemLink
						icon={<ClockSVG className={headerUtilsIconClass + ' roc-utlity-nav__menu-item-icon-clock'} />}
						path={userData?.summary?.isSignedIn ? MY_ACCOUNT_ORDERS_URL : '/order-status'}
						text={Localizer('OrderStatus')}
						testId="order-status"
					/>
					{
						// #endregion
					}
				</>
			)}
			{
				// #region Commerce
			}
			<OrganizationHeaderMenu userData={userData} />

			<HeaderUtilNavItemLink
				icon={
					<ShoppingCartPlusSVG
						className={headerUtilsIconClass + ' roc-utlity-nav__menu-item-icon-small-icon'}
					/>
				}
				path={'/quick-order'}
				text={Localizer('QuickOrder')}
				testId="quickorder"
			/>
			<HeaderUtilNavItemCart userData={userData} />
			{
				// #endregion
			}
			<SiteSwitcher />
		</HeaderUtilNav>
	);
}
