import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * House SVG
 *
 * @returns
 */
function HouseSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 29 32" className={classnames('roc-icon roc-icon-house', className)} {...rest}>
			<path d="M28.486 10.503l-13.091-10.182c-0.231-0.202-0.536-0.326-0.869-0.326s-0.638 0.123-0.87 0.327l0.002-0.001-13.072 10.182c-0.356 0.237-0.587 0.636-0.587 1.089 0 0.022 0.001 0.044 0.002 0.066l-0-0.003v15.981c-0 0.023-0.001 0.049-0.001 0.076 0 2.368 1.92 4.288 4.288 4.288 0.027 0 0.054-0 0.080-0.001l-0.004 0h20.345c0.017 0 0.037 0 0.057 0 2.368 0 4.288-1.92 4.288-4.288 0-0.027-0-0.053-0.001-0.080l0 0.004v-15.981c0.002-0.025 0.003-0.055 0.003-0.085 0-0.443-0.224-0.833-0.565-1.064l-0.005-0.003zM17.436 29.091h-5.818v-11.617h5.818zM26.163 27.636c0.002 0.029 0.004 0.064 0.004 0.098 0 0.751-0.609 1.36-1.36 1.36-0.034 0-0.069-0.001-0.102-0.004l0.005 0h-4.364v-13.072c0.001-0.023 0.002-0.050 0.002-0.078 0-0.762-0.617-1.379-1.379-1.379-0.027 0-0.055 0.001-0.081 0.002l0.004-0h-8.727c-0.024-0.001-0.051-0.002-0.079-0.002-0.751 0-1.36 0.609-1.36 1.36 0 0.034 0.001 0.068 0.004 0.101l-0-0.004v13.072h-4.364c-0.029 0.002-0.064 0.004-0.098 0.004-0.751 0-1.36-0.609-1.36-1.36 0-0.034 0.001-0.069 0.004-0.102l-0 0.005v-15.244l11.617-9.011 11.636 9.011z" />
		</svg>
	);
}

export default HouseSVG;
