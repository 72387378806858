import Axios, { CancelTokenSource } from 'axios';
import { UPDATE_USER_DEFAULT_ORGANIZATION_URL } from 'features/commerce/organization/routes.constants';
import AxiosHelper from 'services/AxiosHelper';

/**
 * Function to update current user default organization.
 */
export async function updateUserDefaultOrganization(organizationId?: string) {
	const requestToken: CancelTokenSource = Axios.CancelToken.source();
	await AxiosHelper.post(
		`/ajax${UPDATE_USER_DEFAULT_ORGANIZATION_URL}`,
		{
			organizationId: organizationId,
		},
		{
			cancelToken: requestToken.token,
		},
	);
}
