import Axios, { AxiosError, CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import AxiosHelper from 'services/AxiosHelper';
import { Localizer } from 'services/Localizer';
import { getUserData } from 'services/UserData';
import Modal from 'shared/components/Modal';
import InlineAlert from 'shared/edit/InlineAlert';
import { INVALIDATE_USER_ORGANIZATION_NOTIFICATION_CACHE_URL } from './routes.constants';

/**
 * Component to notify the current user whenever he got unassigned
 * from his current default organization.
 */
export default function OrganizationRemovalNotification(): JSX.Element | null {
	const [organizationRemovalNotification, setOrganizationRemovalNotification] = useState<string>();
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState<string>();

	useEffect(() => {
		(async () => {
			try {
				const userData = (await getUserData()).data;
				if (userData && userData.summary && userData.summary.userOrganizationRemovalNotification) {
					setOrganizationRemovalNotification(userData.summary.userOrganizationRemovalNotification);
				}
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Function to invalidate user organization notification cache
	 * when closing or clicking ok button of the notification modal.
	 */
	const onOrganizationRemovalNotificationClose = async (): Promise<void> => {
		const requestToken: CancelTokenSource = Axios.CancelToken.source();

		try {
			await AxiosHelper.post(`/ajax${INVALIDATE_USER_ORGANIZATION_NOTIFICATION_CACHE_URL}`, {
				cancelToken: requestToken.token,
			});
			setIsOpen(false);
		} catch (error) {
			if (Axios.isCancel(error)) {
				// if the component was unmounted, there's nothing to do
				return;
			}
			const axiosError = error as AxiosError;
			if (axiosError && axiosError.response && axiosError.response.data && axiosError.response.data.message) {
				setErrorMessage(axiosError.response.data.message);
			}
		}
	};

	if (!organizationRemovalNotification) {
		return null;
	}

	return (
		<Modal
			isOpen={isOpen}
			hideCloseButton={true}
			closeModal={onOrganizationRemovalNotificationClose}
			renderTitle={() => ''}
			renderActions={() => (
				<>
					<button
						type="button"
						onClick={onOrganizationRemovalNotificationClose}
						className="roc-btn roc-modal__btn roc-modal__btn-cancel"
						data-testid="organization-removal-notification-alert-btn"
					>
						{Localizer('Ok')}
					</button>
				</>
			)}
		>
			{errorMessage ? <InlineAlert theme="danger" message={errorMessage} /> : null}
			<div className="roc-icon-text roc-toggle-form__error">
				<div data-testid="inline-alert-window" className="roc-alert roc-alert-warning roc-alert-full-width">
					<div
						dangerouslySetInnerHTML={{ __html: organizationRemovalNotification }}
						data-testid={`organization-removal-notification`}
					></div>
				</div>
			</div>
		</Modal>
	);
}
