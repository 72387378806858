import React from 'react';
import { initReactAppByAttribute } from 'shared/util/ApplicationInit';
import OrganizationRemovalNotification from './OrganizationRemovalNotification';

export function setupOrganizationRemovalNotification() {
	/**
	 * Perform module setup for user organization removal notification
	 */
	const render = initReactAppByAttribute(
		() => <OrganizationRemovalNotification />,
		'data-roc-organization-user-removal-notification',
	);

	render();

	// optional, use this to enable hot reloading
	if ((module as any).hot) {
		(module as any).hot.accept('./OrganizationRemovalNotification', () => {
			render();
		});
	}
}
