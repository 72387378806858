import 'intersection-observer';

if (document.readyState !== 'loading') {
	lazyLoadImages();
} else {
	document.addEventListener('DOMContentLoaded', function () {
		lazyLoadImages();
	});
}

/**
 * Initializes lazy loading for image assets.
 * Example usage:
 * <img class="roc-lazy-image" 
		src="/images/placeholder/placeholder-image.png"
		data-src="/images/my-actual-image.jpg" alt="" />
 */
export function lazyLoadImages() {
	// Get our lazy-loaded images
	const lazyImages = [].slice.call(document.querySelectorAll('.roc-lazy-image'));

	const defaultDelay = 10;
	// Create new observer object
	const lazyImageObserver = new IntersectionObserver(function (entries) {
		// Loop through IntersectionObserverEntry objects
		entries.forEach(function (entry) {
			// Do these if the target intersects with the root
			if (entry.isIntersecting) {
				const lazyImage = entry.target as HTMLImageElement;

				if (lazyImage.dataset.src) {
					lazyImage.src = lazyImage.dataset.src;
				}

				const attributeDelay = Number(lazyImage.getAttribute('data-roc-lazy-delay')) || defaultDelay;
				lazyImage.classList.remove('roc-lazy-image--inactive');

				setTimeout(() => {
					lazyImage.classList.add('roc-lazy-image--loaded');
					lazyImageObserver.unobserve(lazyImage);
				}, attributeDelay);
			}
		});
	});

	// Loop through and observe each image
	lazyImages.forEach(function (lazyImage) {
		lazyImageObserver.observe(lazyImage);
	});
}

interface LazyElementsArgs {
	/**
	 * The elements to observe
	 */
	elements: HTMLElement[];

	/**
	 * The callback function called when the intersection observer fires.
	 */
	callback: (isIntersecting: boolean) => void;

	/**
	 * The amount of time to delay before calling the callback. Defaults to 0.
	 */
	delay?: number;

	/**
	 * Stop listening to changes once the element has been in the viewport.
	 */
	stopAfterIntersecting?: boolean;
}

/**
 * Helper to receive a callback when an element becomes visible in a viewport, or when it leaves the viewport.
 * This is useful if you need to do a vanilla JS implementation (without React).
 */
export function lazyElements({ elements, callback, delay = 0, stopAfterIntersecting = true }: LazyElementsArgs) {
	const lazyElementObserver = new IntersectionObserver(function (entries) {
		entries.forEach((entry) => {
			setTimeout(() => {
				callback(entry.isIntersecting);
			}, delay);

			if (stopAfterIntersecting && entry.isIntersecting) {
				lazyElementObserver.unobserve(entry.target);
			}
		});
	});

	elements.forEach((lazyElement) => {
		lazyElementObserver.observe(lazyElement);
	});
}
