import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Bookmark SVG
 *
 * @returns
 */
function BookmarkSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 19 24" className={classnames('roc-icon icon-bookmark', className)} {...rest}>
			<path d="M15.693 0h-12.071c-2.052 0-3.621 1.559-3.621 3.598v19.191c0 0.48 0.241 0.84 0.604 1.080s0.845 0.12 1.207-0.12l7.726-5.518 7.726 5.518c0.241 0.12 0.483 0.24 0.724 0.24s0.362 0 0.604-0.12c0.362-0.24 0.604-0.6 0.604-1.080v-19.191c0.121-2.039-1.449-3.598-3.501-3.598v0zM16.9 20.511l-6.519-4.678c-0.241-0.12-0.483-0.24-0.724-0.24s-0.483 0.12-0.724 0.24l-6.519 4.678v-16.912c0-0.72 0.483-1.199 1.207-1.199h12.071c0.724 0 1.207 0.48 1.207 1.199v16.912z"></path>
		</svg>
	);
}

export default BookmarkSVG;
