import { useState } from 'react';

/**
 * Custom hook for commonly used loading states.
 */
export function useLoader<T>() {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [response, setResponse] = useState<T | null>(null);

	return {
		loading,
		setLoading,
		error,
		setError,
		response,
		setResponse,
	};
}
