import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Garbage Can SVG
 *
 * @returns
 */
function GarbageCanCircleSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('icon icon-garbage-circle', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path
				fill="#d9534f"
				d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"
			/>
			<path
				fill="#fff"
				d="M24.652 11.459c-0.005-0.239-0.199-0.43-0.439-0.43-0.006 0-0.013 0-0.019 0l0.001-0h-3.2l-0.989-2.222c-0.353-0.691-1.049-1.162-1.857-1.188l-0.004-0h-4.078c-0.816 0.028-1.517 0.497-1.872 1.177l-0.006 0.012-0.98 2.222h-3.134c-0.005-0-0.012-0-0.018-0-0.239 0-0.434 0.192-0.439 0.43v0.851c0.001 0.238 0.195 0.43 0.433 0.43 0.009 0 0.017-0 0.026-0.001l-0.001 0h1.352l0.529 9.493c-0.004 0.054-0.007 0.117-0.007 0.18 0 1.293 0.985 2.357 2.245 2.481l0.010 0.001h7.8c1.277-0.15 2.258-1.226 2.258-2.531 0-0.068-0.003-0.135-0.008-0.202l0.001 0.009 0.6-9.431h1.352c0.005 0 0.012 0 0.018 0 0.239 0 0.434-0.192 0.439-0.43v-0l-0.011-0.85zM13.867 9.472c0.061-0.069 0.142-0.12 0.235-0.143l0.003-0.001h4.029c0.093 0.029 0.173 0.078 0.238 0.143l0 0 0.677 1.557h-5.879zM20.012 23.186h-7.822c-0.281-0.216-0.46-0.552-0.46-0.93 0-0.029 0.001-0.058 0.003-0.087l-0 0.004-0.523-9.434h9.839l-0.6 9.431c0.002 0.028 0.004 0.060 0.004 0.093 0 0.373-0.174 0.705-0.445 0.92l-0.002 0.002z"
			/>
			<path
				fill="#fff"
				d="M14.993 15.067h-0.898c-0.005-0-0.012-0-0.018-0-0.239 0-0.434 0.192-0.439 0.43v4.842c0.005 0.239 0.199 0.43 0.439 0.43 0.006 0 0.013-0 0.019-0l-0.001 0h0.898c0.005 0 0.012 0 0.018 0 0.239 0 0.434-0.192 0.439-0.43v-4.842c-0.008-0.239-0.204-0.43-0.445-0.43-0.004 0-0.009 0-0.013 0h0.001zM18.328 15.067h-0.898c-0.005-0-0.012-0-0.018-0-0.239 0-0.434 0.192-0.439 0.43v4.842c0.005 0.239 0.199 0.43 0.439 0.43 0.006 0 0.013-0 0.019-0l-0.001 0h0.898c0.005 0 0.012 0 0.018 0 0.239 0 0.434-0.192 0.439-0.43v-4.842c-0.008-0.239-0.204-0.43-0.445-0.43-0.004 0-0.009 0-0.013 0h0.001z"
			/>
		</svg>
	);
}

export default GarbageCanCircleSVG;
