import Axios, { CancelTokenSource, AxiosError } from 'axios';
import { useState } from 'react';
import AxiosHelper from 'services/AxiosHelper';
import { useAxiosPaginatedLoader } from 'shared/hooks/useAxiosPaginatedLoader';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import { LocationDto, StoreLocatorSearchResults } from './types';

export interface StoreLocatorState {
	/**
	 * If true, the default store location is being saved on the server
	 */
	isSaving: boolean;
	/**
	 * If true, an error has occured when saving default store location
	 */
	savingError: Error | AxiosError | null;
	/**
	 * If true, an error has occured when searching store locations
	 */
	loadingError: Error | AxiosError | null;
	/**
	 * The default store location, if any
	 */
	defaultStoreLocation?: LocationDto;
	/**
	 * If true, the search is in progress
	 */
	isLoading: boolean;
	/**
	 * The last search results, if any
	 */
	searchResults: StoreLocatorSearchResults | null | undefined;
	/**
	 * The search text to look up stores by. Can include zip/city/etc.
	 */
	searchText: string;
	/**
	 * Determines whether searches are being executed right now or not. This is passed to useAxiosPaginatedLoader.
	 */
	dataLoaderActive: boolean;
}

export interface StoreLocatorActor {
	saveDefaultLocation: (location: LocationDto) => Promise<void>;
	setSearchText: (text: string) => void;
	goToPage: (pageNumber: number) => void;
	goToNextPage: () => void;
	goToPreviousPage: () => void;
	setDataLoaderActive: (active: boolean) => void;
}

export interface UseStoreLocator {
	state: StoreLocatorState;
	actor: StoreLocatorActor;
}

export interface UseStoreLocatorParams {
	/**
	 * Initial search text, please provide if you want to perform search on init
	 */
	initSearchText?: string;
	/**
	 * Current default store location or null if there is no default location
	 */
	defaultStoreLocation?: LocationDto;
	/**
	 * The page size for store locator search results
	 */
	pageSize?: number;
}

/**
 * Reusable hook to access Store Location-related endpoints on the server.
 */
export function useStoreLocator(useStoreLocationParams: UseStoreLocatorParams): UseStoreLocator {
	const { defaultStoreLocation } = useStoreLocationParams;
	const pageSize = useStoreLocationParams.pageSize || 10;

	const [savingError, setSavingError] = useState<Error | AxiosError | null>(null);
	const [isSaving, setIsSaving] = useState(false);
	const [searchText, setSearchTextInternal] = useState<string>(useStoreLocationParams.initSearchText || '');
	const [dataLoaderActive, setDataLoaderActive] = useState<boolean>(false);

	const { goToPage, goToNextPage, goToPreviousPage, setQuery, error, loading, results } = useAxiosPaginatedLoader<
		LocationDto,
		StoreLocatorSearchResults
	>({
		axiosParams: {
			url: '/ajax/store-locator/',
		},
		query: `searchText=${searchText}`,
		initialPageSize: pageSize,
		active: dataLoaderActive,
	});

	// initialize ROC event dispatched that will be used to dispatch events anytime a new store location is saved
	function locationChangeDispatcher(location?: LocationDto) {
		dispatchRocEvent('roc-default-store-location-changed', { location });
	}

	function setSearchText(value: string) {
		setSearchTextInternal(value);

		if (setQuery) {
			setQuery(`searchText=${value}`);
		}
	}

	/**
	 * Marks a store location as default on the server
	 */
	const saveDefaultLocation = async (location: LocationDto): Promise<void> => {
		setIsSaving(true);

		const requestToken: CancelTokenSource = Axios.CancelToken.source();

		try {
			await AxiosHelper.post(`/ajax/store-locator/set-default/${location.id}`, {
				searchString: searchText,
				distance: location.distance,
				cancelToken: requestToken.token,
			});

			setSavingError(null);
			locationChangeDispatcher(location);
		} catch (error) {
			if (Axios.isCancel(error)) {
				// if the component was unmounted, there's nothing to do
				return;
			}

			setSavingError(error);
			console.error('An error occurred while saving is default.', error);
		} finally {
			setIsSaving(false);
		}
	};

	return {
		state: {
			isSaving,
			savingError: savingError,
			loadingError: error ?? null,
			defaultStoreLocation,
			isLoading: loading,
			searchResults: results,
			searchText,
			dataLoaderActive,
		},
		actor: {
			saveDefaultLocation,
			setSearchText,
			goToNextPage,
			goToPreviousPage,
			goToPage,
			setDataLoaderActive,
		},
	};
}
