export const MY_ACCOUNT_PATH_PREFIX = '/my-account';
export const MY_ACCOUNT_PERSONAL_DATA_URL = `${MY_ACCOUNT_PATH_PREFIX}/personal-data`;
export const MY_ACCOUNT_AUTHENTICATOR_URL = `${MY_ACCOUNT_PATH_PREFIX}/settings/authenticator`;
export const MY_ACCOUNT_GENERATE_RECOVERY_CODES_URL = `${MY_ACCOUNT_AUTHENTICATOR_URL}/generate-recovery-codes`;
export const MY_ACCOUNT_CONFIGURE_AUTHENTICATOR_URL = `${MY_ACCOUNT_AUTHENTICATOR_URL}/configure`;
export const MY_ACCOUNT_LOGIN_URL = `${MY_ACCOUNT_PATH_PREFIX}/login`;
export const MY_ACCOUNT_LOGOUT_URL = `${MY_ACCOUNT_PATH_PREFIX}/logout`;
export const MY_ACCOUNT_ADDRESS_BOOK_URL = `${MY_ACCOUNT_PATH_PREFIX}/address-book`;
export const MY_ACCOUNT_ADDRESS_BOOK_CREATE_URL = `${MY_ACCOUNT_ADDRESS_BOOK_URL}/create`;
export const MY_ACCOUNT_ADDRESS_BOOK_EDIT_URL = `${MY_ACCOUNT_ADDRESS_BOOK_URL}/edit`;
export const MY_ACCOUNT_WALLET_URL = `${MY_ACCOUNT_PATH_PREFIX}/wallet`;
export const MY_ACCOUNT_WALLET_CREATE_URL = `${MY_ACCOUNT_WALLET_URL}/create`;
export const MY_ACCOUNT_SETTINGS_URL = `${MY_ACCOUNT_PATH_PREFIX}/settings`;

// #region Commerce
export const MY_ACCOUNT_ORDERS_URL = `${MY_ACCOUNT_PATH_PREFIX}/orders`;
export const MY_ACCOUNT_STORE_URL = `${MY_ACCOUNT_PATH_PREFIX}/store`;
export const MY_ACCOUNT_SAVED_FOR_LATER_URL = `${MY_ACCOUNT_PATH_PREFIX}/saved-for-later`;
export const MY_ACCOUNT_SAVED_CARTS_URL = `${MY_ACCOUNT_PATH_PREFIX}/saved-carts`;
export const MY_ACCOUNT_BUY_AGAIN_URL = `${MY_ACCOUNT_PATH_PREFIX}/buy-again`;
export const MY_ACCOUNT_SHOPPING_LISTS_URL = `${MY_ACCOUNT_PATH_PREFIX}/shopping-lists`;
export const MY_ACCOUNT_ORGANIZATION_REVIEW_URL = `${MY_ACCOUNT_PATH_PREFIX}/organization-review`;
export const MY_ACCOUNT_ORGANIZATION_SELECTOR_URL = `${MY_ACCOUNT_PATH_PREFIX}/organization-selector`;
export const MY_ACCOUNT_SHOPPING_LISTS_CREATE_URL = `${MY_ACCOUNT_SHOPPING_LISTS_URL}/create`;
// #endregion
