import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { DropdownOption } from 'shared-client/types/fields';
import { toKebabCase } from 'util/StringUtil';
import { FormFieldProps, ValidationMessages } from '.';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import FieldLabel from './FieldLabel';
import classnames from 'classnames';
import FieldGroup from './FieldGroup';
export type DropdownProps = FormFieldProps & {
	addSelectOption?: boolean;
	options: DropdownOption[];
};

export type DropdownFieldProps = DropdownProps &
	FieldProps<DropdownOption, FieldRenderProps<DropdownOption, HTMLElement>>;

/**
 * Component that renders a Dropdown field component
 */
export default function DropdownField({
	name,
	isRequired = true,
	readOnly = false,
	disabled = false,
	addSelectOption = true,
	options,
	wrapperClassName = '',
	className = '',
	label = '',
	afterSubmit,
	allowNull,
	beforeSubmit,
	formatOnBlur,
	isEqual,
	subscription,
	validateFields,
	testId,
	//region EFG : customization
	ariaLive,
	ariaRequired,
	//endregion EFG : customization
}: DropdownFieldProps) {
	return (
		<Field
			name={name}
			afterSubmit={afterSubmit}
			allowNull={allowNull}
			beforeSubmit={beforeSubmit}
			formatOnBlur={formatOnBlur}
			isEqual={isEqual}
			subscription={subscription}
			validateFields={validateFields}
		>
			{({ input: { value, onChange, onFocus, onBlur }, meta }) => (
				<FieldGroup name={name} wrapperClassName={wrapperClassName}>
					<FieldLabel label={label} name={name} isRequired={isRequired} htmlFor={name} />
					<FieldInputErrorWrapper name={name} hideIcon>
						<select
							className={classnames('roc-form-input__input', {
								[`${className}`]: className,
							})}
							id={name}
							name={name}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							onFocus={onFocus}
							disabled={disabled}
							aria-describedby={name + '-invalid'}
							data-testid={testId || `field-${toKebabCase(name)}`}
							//region EFG : customization
							aria-live={ariaLive}
							aria-required={ariaRequired}
							//endregion EFG : customization
						>
							{addSelectOption ? <option value="" /> : null}
							{options.map((option, index) => (
								<option
									key={index}
									value={option.value}
									disabled={readOnly}
									data-testid={`field-${toKebabCase(name)}-option-${toKebabCase(option.text)}`}
								>
									{option.text}
								</option>
							))}
						</select>
					</FieldInputErrorWrapper>
					<ValidationMessages id={name + '-invalid'} meta={meta} />
				</FieldGroup>
			)}
		</Field>
	);
}
