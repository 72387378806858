import Axios, { AxiosError, CancelTokenSource } from 'axios';
import { MY_ACCOUNT_ADDRESS_BOOK_URL } from 'features/my-account/routes.constants';
import { useEffect, useState } from 'react';
import AxiosHelper from 'services/AxiosHelper';
import { getUserData } from 'services/UserData';
import { OrganizationShipTo } from 'shared-client/types/commerce/organization';
import { AddressBookDto } from '../../address-book/useAddressBookLoader';

export interface UseShipToPickerResult {
	isLoading: boolean;
	selectedShipTo: OrganizationShipTo | null;
	hasMultipleShipTos: boolean | null;
	setSelectedShipToId: (shipToId: string) => void;
	isSettingSelectedShipTo: boolean;
	setLoadShipTos: (newValue: boolean) => void;
	isLoadingShipTos: boolean;
	shipTos: OrganizationShipTo[] | null;
	error: AxiosError | Error | null;
}

/**
 * Custom hook to load user's ship-to from services/UserData (getUserData) promise and to handle default
 * store ship-to changes on the client by listening to browser events
 */
export function useShipToPickerLoader(): UseShipToPickerResult {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedShipTo, setSelectedShipTo] = useState<OrganizationShipTo | null>(null);
	const [hasMultipleShipTos, setHasMultipleShipTos] = useState<boolean | null>(null);
	const [isSettingSelectedShipTo, setIsSettingSelectedShipTo] = useState(false);
	const [loadShipTos, setLoadShipTos] = useState(false);
	const [shipTos, setShipTos] = useState<OrganizationShipTo[] | null>(null);
	const [isLoadingShipTos, setIsLoadingShipTos] = useState(false);
	const [error, setError] = useState<AxiosError | Error | null>(null); // error is never cleared. Probably a bug

	// When the component mounts, begin loading the current user's selected ship-to.
	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const userDataResponse = (await getUserData()).data;
				if (userDataResponse.summary) {
					if (userDataResponse.summary.selectedShipTo) {
						setSelectedShipTo(userDataResponse.summary?.selectedShipTo);
					}
					if (userDataResponse.summary.hasMultipleShipTos) {
						setHasMultipleShipTos(userDataResponse.summary.hasMultipleShipTos);
					}
				}
			} catch (error) {
				if (Axios.isCancel(error)) {
					return;
				}
				setError(error);
				const axiosError = error as AxiosError;
				console.error(`An error occurred while loading user data: ${axiosError.message}`);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	// When ship-tos are requested to be loaded, load them using a network call
	useEffect(() => {
		if (!loadShipTos) {
			return;
		}
		const requestToken = Axios.CancelToken.source();
		(async () => {
			const url = `/ajax${MY_ACCOUNT_ADDRESS_BOOK_URL}`;
			try {
				const response = await AxiosHelper.get<AddressBookDto>(url, {
					cancelToken: requestToken.token,
				});

				setShipTos(response?.data?.organizationShipTos);
			} catch (error) {
				if (Axios.isCancel(error)) {
					return;
				}
				setError(error);
				const axiosError = error as AxiosError;
				console.error(`An error occurred while loading user ship-tos: ${axiosError.message}`);
			} finally {
				setIsLoadingShipTos(false);
			}
		})();
	}, [loadShipTos]);

	/**
	 * Set the selected ship-to id as the user's selected ship-to id on the client and server
	 * @param shipToId
	 */
	const setSelectedShipToId = async (shipToId: string) => {
		if (!shipToId) {
			return;
		}

		const newShipTo = shipTos?.find((st) => st.id === shipToId) ?? null;

		const requestToken: CancelTokenSource = Axios.CancelToken.source();
		try {
			setIsSettingSelectedShipTo(true);
			const payload = { shipToId };
			await AxiosHelper.post('/ajax/user/ship-to', payload, {
				cancelToken: requestToken.token,
			});

			setSelectedShipTo(newShipTo);
		} catch (error) {
			if (Axios.isCancel(error)) {
				return;
			}
			setError(error);
			const axiosError = error as AxiosError;
			console.log(`An error occurred while setting the user's ship-to: ${axiosError.message}`);
		} finally {
			setIsSettingSelectedShipTo(false);
		}
	};

	return {
		isLoading,
		selectedShipTo,
		hasMultipleShipTos,
		setSelectedShipToId,
		isSettingSelectedShipTo,
		setLoadShipTos,
		isLoadingShipTos,
		shipTos,
		error: error,
	};
}
