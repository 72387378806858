import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { toKebabCase } from 'util/StringUtil';
import { FormFieldProps, ValidationMessages } from '.';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import FieldLabel from './FieldLabel';
import classnames from 'classnames';
import FieldGroup from './FieldGroup';

export interface BaseInputProps extends FormFieldProps {
	type?: string;
	step?: string;
}

export type InputFieldProps = FieldRenderProps<string, HTMLElement> &
	BaseInputProps & {
		placeholder?: string;
	};

export type HTMLInputRef = React.MutableRefObject<any>;
/**
 * Component that renders an input with form field.
 */
const InputField = React.forwardRef((props: InputFieldProps, ref: HTMLInputRef) => {
	const {
		input: { name, onChange, value },
		meta,
		type,
		label = '',
		isRequired = true,
		disabled = false,
		readOnly = false,
		placeholder = '',
		wrapperClassName = '',
		className = '',
		step,
		testId,
		//#region : EFG Customization
		ariaRequired,
		ariaLive,
		//#endregion
	} = props;

	return (
		<FieldGroup name={name} wrapperClassName={wrapperClassName}>
			<FieldLabel label={label} name={name} isRequired={isRequired} htmlFor={name} />
			<FieldInputErrorWrapper name={name} inline={type === 'checkbox'}>
				<input
					ref={ref}
					className={classnames('roc-form-input__input', {
						[`${className}`]: className,
					})}
					id={name}
					name={name}
					type={type}
					value={value}
					onChange={onChange}
					disabled={disabled}
					step={step}
					readOnly={readOnly}
					aria-describedby={name + '-invalid'}
					//#region : EFG Customization
					aria-required={ariaRequired}
					aria-live={ariaLive}
					//#endregion
					placeholder={placeholder}
					data-testid={testId || `field-${toKebabCase(name)}`}
				/>
			</FieldInputErrorWrapper>
			<ValidationMessages id={name + '-invalid'} meta={meta} />
		</FieldGroup>
	);
});

export default InputField;
