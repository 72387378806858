import { useEffect, useState } from 'react';
import { SortByDirection } from 'shared-client/types/index';
import { BaseDto, PaginatedResult } from 'shared/types';
import { useAxiosLoader, UseAxiosLoaderParams } from './useAxiosLoader';

/**
 * Use Axios Paginated Loader Params
 * @param axiosParams The parameters for the request being passed into axios. This includes the method, url, data, etc.
 * @param callback A function that is called after a form is posted. It'll include a parameter with the error if something went wrong.
 */
export interface UseAxiosPaginatedLoaderParams<TDto extends BaseDto, T extends PaginatedResult<TDto>>
	extends UseAxiosLoaderParams {
	query?: string;
	initialPageSize?: number;
	initialSortByName?: string;
	initialSortDirection?: SortByDirection;
	initialResults?: T;
	cache?: boolean;
}

/**
 * The useAxiosPaginatedLoader hook result object
 */
export interface UseAxiosPaginatedLoaderResult<TDto extends BaseDto, T extends PaginatedResult<TDto>> {
	/**
	 * Current page number
	 */
	page: number;
	/**
	 * Go to page and load results
	 */
	goToPage: (page: number) => void;
	/**
	 * Go to next page and load results
	 */
	goToNextPage: () => void;
	/**
	 * Go to previous page and load results
	 */
	goToPreviousPage: () => void;
	/**
	 * Set sorting and load the results
	 */
	setSorting: (sortBy: string, direction: SortByDirection) => void;
	/**
	 * Set page size and load the results
	 */
	setPageSize: (pageSize: number) => void;
	/**
	 * Sets the custom query
	 */
	setQuery?: (query: string) => void;
	/**
	 * Sets the sort by name
	 */
	setSortByName?: (sortBy: string) => void;
	/**
	 * Sets the sort direction
	 */
	setSortDirection?: (direction: SortByDirection) => void;
	/**
	 * Current sort by name
	 */
	sortByName: string | undefined;
	/**
	 * Current sort by direction
	 */
	sortDirection: SortByDirection | undefined;
	/**
	 * True if loading results
	 */
	loading: boolean;
	/**
	 * Error object if an error occurred while loading the results
	 */
	error: Error | undefined;
	/**
	 * Current paginated results object
	 */
	results: T | null | undefined;

	/**
	 * Custom query string that includes
	 * all the other parameters other than pagination and sorting ones
	 */
	customQuery?: string | undefined;

	/**
	 * If this function has been called another request will be triggered to get fresh results
	 */
	reload?: () => void;
}

/**
 * A reusable hook for pagination (data is loaded with axios).
 */
export function useAxiosPaginatedLoader<TDto extends BaseDto, T extends PaginatedResult<TDto>>({
	axiosParams,
	active = true,
	callback,
	query,
	initialPageSize,
	initialSortByName,
	initialSortDirection,
	initialResults,
	cache,
}: UseAxiosPaginatedLoaderParams<TDto, T>): UseAxiosPaginatedLoaderResult<TDto, T> {
	const [pageSize, setPageSizeState] = useState(initialPageSize);
	const [pageNumber, setPageNumber] = useState(1);
	const [sortByName, setSortByName] = useState(initialSortByName);
	const [sortDirection, setSortDirection] = useState(initialSortDirection);
	const [dataLoaderActive, setDataLoaderActive] = useState(initialResults === undefined);
	const [customQuery, setCustomQuery] = useState(query);

	useEffect(() => {
		setDataLoaderActive(true);
	}, [pageNumber, sortByName, sortDirection, pageSize]);

	const goToPage = (page: number) => {
		if (results && page > 0 && page <= results.pagination.totalPages) {
			setPageNumber(page);
		}
	};

	const goToNextPage = () => {
		if (results && pageNumber < results.pagination.totalPages) {
			setPageNumber(pageNumber + 1);
		}
	};

	const goToPreviousPage = () => {
		if (pageNumber === 1) {
			return;
		}

		setPageNumber(pageNumber - 1);
	};

	const setSorting = (sortBy: string, direction: SortByDirection) => {
		setPageNumber(1);
		setSortByName(sortBy);
		setSortDirection(direction);
	};

	const setQuery = (customQuery: string | undefined) => {
		setPageNumber(1);
		setCustomQuery(customQuery);
	};

	const paginationQuery = `${pageSize ? `pagesize=${pageSize}` : ''}&pageNumber=${pageNumber}${
		sortByName ? `&sortBy=${sortByName}` : ''
	}${sortDirection ? `&sortDirection=${sortDirection}` : ''}`;

	const url = `${axiosParams.url}${customQuery ? `?${customQuery}&${paginationQuery}` : `?${paginationQuery}`}`;

	const { loading, error, response, reload } = useAxiosLoader<T>({
		axiosParams: {
			...axiosParams,
			url: url,
		},
		active: active && dataLoaderActive,
		callback,
		cacheKey: cache ? url : undefined,
	});

	const results = active && dataLoaderActive ? response && response.data : initialResults;

	const setPageSize = (size: number) => {
		setPageSizeState(size);
	};

	return {
		page: pageNumber,
		goToPage,
		goToNextPage,
		goToPreviousPage,
		setSorting,
		setPageSize,
		setQuery,
		setSortByName,
		setSortDirection,
		reload,
		customQuery,
		sortByName,
		sortDirection,
		loading,
		error,
		results,
	};
}
