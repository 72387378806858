import React, { useEffect, useState } from 'react';
import { getUserData, UserAnnouncement, UserData } from 'services/UserData';
import { ServerData } from 'services/ServerData';
import InlineAlert from 'shared/edit/InlineAlert';
import RichTextContent from 'shared/components/RichTextContent';

export interface AnnouncementsProps extends JSX.IntrinsicAttributes {
	/** The name of the page that is rendering announcements */
	rocAnnouncement: string;
}

/**
 * Interface for storing annoucementEntry in local storage.
 */
export interface StorageAnnouncementEntry {
	/** Announcement version */
	rowVersion: Uint8Array;

	/**Viewed announcement timestamp for storing in localStorage. Used for recording when an announcement is viewed by user */
	viewedTimestamp: number;

	/**Dismissed announcement timestamp for storing in localStorage.Used for recording when an announcement is dismissed by user */
	dismissedTimestamp?: number;
}

/**
 * Displays a list of announcements within the view component Roc.Web\Features\Announcements\Components\Announcements\Default.cshtml.
 * In order to properly handle caching, announcements are populated via a call to /ajax/user.
 * @param props
 */
export default function Announcements(props: AnnouncementsProps): JSX.Element | null {
	const [announcements, setAnnouncements] = useState<UserAnnouncement[] | undefined>([]);
	const [isDismissed, setIsDismissed] = useState<boolean>(false);
	const keyName: string = 'RocAnnouncementsContext_';
	const [announcementDictionary, setAnnouncementDictionary] = useState<Record<
		string,
		StorageAnnouncementEntry
	> | null>(null);

	useEffect(() => {
		(async () => {
			try {
				const userData = (await getUserData()).data;
				const userId = userData.summary ? userData.summary.id : null;
				const siteId = ServerData.CURRENT_SITE_HOST ? ServerData.CURRENT_SITE_HOST.SiteId : null;
				const storageKey = generateKey(keyName, siteId, userId);

				// Get the announcements for a given page.
				// If announcement.pages is an empty array, the announcement targets all pages
				const filteredAnnouncements = userData?.summary?.announcements.filter((a) => {
					return a.pages.length === 0 || a.pages.includes(props.rocAnnouncement);
				});

				setAnnouncements(filteredAnnouncements);
				updateAnnouncementsLocalStorage(userData, filteredAnnouncements);

				const localStorageAnnouncements = loadAnnouncementsData(storageKey);

				if (localStorageAnnouncements) {
					let storageAnnouncements: Record<string, StorageAnnouncementEntry> = {};
					try {
						storageAnnouncements = JSON.parse(localStorageAnnouncements) as Record<
							string,
							StorageAnnouncementEntry
						>;
					} catch (error) {
						console.error('Unable to parse localStorage data.', error);
					}
					setAnnouncementDictionary(storageAnnouncements);
				}
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function updateAnnouncementsLocalStorage(
		userData: UserData,
		filteredAnnouncements: UserAnnouncement[] | undefined,
	) {
		const userId = userData.summary ? userData.summary.id : null;
		const siteId = ServerData.CURRENT_SITE_HOST ? ServerData.CURRENT_SITE_HOST.SiteId : null;
		const storageKey = generateKey(keyName, siteId, userId);
		const storageItem = loadAnnouncementsData(storageKey);
		let storageAnnouncements: Record<string, StorageAnnouncementEntry> = {};

		if (storageItem) {
			try {
				storageAnnouncements = JSON.parse(storageItem) as Record<string, StorageAnnouncementEntry>;
			} catch (error) {
				console.error('Unable to parse localStorage data.', error);
			}
		}

		//Updates localStorage with announcements from the current page
		//and deletes expired announcements
		updateExistingAndDeleteExpired(filteredAnnouncements, storageAnnouncements, userData);
		localStorage.setItem(storageKey, JSON.stringify(storageAnnouncements));
	}

	async function dismissAnnouncements() {
		try {
			if (!announcements) {
				return;
			}
			const userData = (await getUserData()).data;
			const userId = userData.summary ? userData.summary.id : null;
			const siteId = ServerData.CURRENT_SITE_HOST ? ServerData.CURRENT_SITE_HOST.SiteId : null;

			dismissStorageAnnouncements(announcements, keyName, siteId, userId);
		} catch (error) {
			console.error('Unable to save data to local storage.', error);
		} finally {
			setIsDismissed(true);
		}
	}

	if (
		announcements == null ||
		announcements.length === 0 ||
		isDismissed ||
		announcementDictionary == null ||
		announcements?.filter(
			(announcement) =>
				!announcementDictionary[announcement.id].dismissedTimestamp ||
				announcementDictionary[announcement.id].rowVersion != announcement.rowVersion,
		).length == 0
	) {
		return null;
	}

	return (
		<div data-testid="announcements-container">
			<InlineAlert
				onCollapse={dismissAnnouncements}
				isCollapsed={isDismissed}
				theme="warning"
				title=""
				message=""
			>
				{announcements
					.filter(
						(announcement) =>
							!announcementDictionary[announcement.id].dismissedTimestamp ||
							announcementDictionary[announcement.id].rowVersion != announcement.rowVersion,
					)
					.map((announcement, index) => {
						return (
							<RichTextContent
								htmlContent={announcement.message}
								key={announcement.id}
								dataTestId={`announcement-item-${index}`}
							/>
						);
					})}
			</InlineAlert>
		</div>
	);
}

/**Update localStorage with dismissed announcements from the page.
 * Used for storing announcements which the user dismissed.
 * If an announcement is dismissed we add a dismisedTimestamp to it.
 */
export async function dismissStorageAnnouncements(
	announcements: UserAnnouncement[],
	keyName: string,
	siteId: string | null,
	userId: string | null,
) {
	announcements.forEach((announcement) => (announcement.dismissedTimestamp = new Date().valueOf()));
	const storageKey = generateKey(keyName, siteId, userId);
	const getStorageAnnouncements = loadAnnouncementsData(storageKey);
	let storageAnnouncements: Record<string, StorageAnnouncementEntry> = {};

	if (getStorageAnnouncements) {
		try {
			storageAnnouncements = JSON.parse(getStorageAnnouncements) as Record<string, StorageAnnouncementEntry>;
		} catch (error) {
			console.error('Unable to parse localStorage data.', error);
		}
	}
	for (const announcement of announcements) {
		storageAnnouncements[announcement.id] = {
			viewedTimestamp: announcement.viewedTimestamp,
			dismissedTimestamp: announcement.dismissedTimestamp,
			rowVersion: announcement.rowVersion,
		};
	}
	localStorage.setItem(storageKey, JSON.stringify(storageAnnouncements));
}

/**Updates localStorage with Announcements from the page.
 * The announcements which the user viewded are stored in localStorage.
 * If announcement from the current page is already stored in localStorage
 * we check if it's version is newer so that newer version can be added
 * to localStorage. If the version is the same as in localStorage we don't update it.
 */

export function updateAnnouncements(
	filteredAnnouncements: UserAnnouncement[] | undefined,
	storageAnnouncements: Record<string, StorageAnnouncementEntry>,
) {
	if (!filteredAnnouncements) {
		return;
	}
	for (const announcement of filteredAnnouncements) {
		announcement.viewedTimestamp = new Date().valueOf();

		if (
			!storageAnnouncements[announcement.id] ||
			storageAnnouncements[announcement.id].rowVersion !== announcement.rowVersion
		) {
			storageAnnouncements[announcement.id] = {
				viewedTimestamp: announcement.viewedTimestamp,
				rowVersion: announcement.rowVersion,
			};
		}
	}
}

/**Deletes expired announcements from localStorage.
 *Announcements which are stored in locaStorage but are expired should be removed from localStorage.
 *If a user had previously viewed or dismissed expired announcements, these announcements will be removed
 *from localStorage as they are no longer needed.
 */
export function deleteExpiredAnnouncements(
	userData: UserData,
	storageAnnouncements: Record<string, StorageAnnouncementEntry>,
) {
	const announcementsIds = userData?.summary?.announcements.map((a: { id: string }) => a.id);
	const expiredAnnouncementsIds = Object.keys(storageAnnouncements).filter(
		(value) => !announcementsIds?.includes(value),
	);
	for (const expiredId of expiredAnnouncementsIds) {
		delete storageAnnouncements[expiredId];
	}
}

/**Update localStorage with announcements from the page
 * and deletes expired announcements
 */
export function updateExistingAndDeleteExpired(
	filteredAnnouncements: UserAnnouncement[] | undefined,
	storageAnnouncements: Record<string, StorageAnnouncementEntry>,
	userData: UserData,
) {
	//Delete Expired Announcements from local storage if there are any
	deleteExpiredAnnouncements(userData, storageAnnouncements);

	//Update local storage with the announcements from the current page
	updateAnnouncements(filteredAnnouncements, storageAnnouncements);
}

/**Generates key for storing
 * announcements in local storage.
 */
export function generateKey(keyName: string, siteId: string | null, userId: string | null) {
	const storageKey = `${keyName}${siteId}_${userId}`;
	return storageKey;
}

/**Loads announcements from local storage
 * based on a key provided value
 */
export function loadAnnouncementsData(storageKey: string) {
	const getStorageAnnouncements = localStorage.getItem(storageKey);
	return getStorageAnnouncements;
}
