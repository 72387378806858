import React from 'react';
import { SVGProps } from './SVGProps';

/**
 * Shopping Cart SVG
 *
 * @returns
 */
function ShoppingCartSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 35 32" className={'roc-icon ' + className} {...rest}>
			<path d="M16.112 28.14c0 1.422-1.202 2.573-2.685 2.573s-2.685-1.152-2.685-2.573c0-1.422 1.202-2.573 2.685-2.573s2.685 1.152 2.685 2.573zM30.881 28.14c0 1.422-1.202 2.573-2.685 2.573s-2.685-1.152-2.685-2.573c0-1.422 1.202-2.573 2.685-2.573s2.685 1.152 2.685 2.573zM33.298 8.067c0.269 0.257 0.269 0.643 0.269 1.029l-2.148 10.808c-0.403 1.801-2.014 3.088-4.028 3.088h-13.024c-0.134 0-0.134 0-0.134 0-1.88 0-3.491-1.287-3.894-3.088l-2.283-10.68c0 0 0-0.129 0-0.129l-1.074-5.404h-4.296c-0.806 0-1.343-0.515-1.343-1.287s0.537-1.287 1.343-1.287h5.371c0.671 0 1.208 0.386 1.343 1.029l1.074 5.404h21.751c0.403 0 0.806 0.129 1.074 0.515zM28.733 19.39l1.88-9.264h-19.603l2.014 9.264c0.134 0.643 0.671 1.029 1.343 1.029h13.024c0.671 0 1.208-0.386 1.343-1.029z" />
		</svg>
	);
}

ShoppingCartSVG.defaultProps = { className: '' };

export default ShoppingCartSVG;
