import type PreHeader from 'features/pre-header/PreHeader';
import React, { Suspense } from 'react';
import { initReactApp } from 'shared/util/ApplicationInit';
const LazyPreHeader = React.lazy<typeof PreHeader>(
	() => import(/* webpackChunkName: "pre-header" */ 'features/pre-header/PreHeader'),
);

/**
 * Renders impersonation header in the roc-pre-header div
 */
export default function RenderImpersonationHeader() {
	return initReactApp(
		() => (
			<Suspense fallback={<div>Loading Impersonation Controls...</div>}>
				<LazyPreHeader />
			</Suspense>
		),
		'roc-pre-header',
	)();
}
