import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Axios from 'axios';
import { LocationDto } from '../store-locator/types';
import { getUserData } from 'services/UserData';
import useRocEventHandler from 'shared/hooks/useRocEventHandler';

export interface UseDefaultStoreLocationResult {
	isLoading: boolean;
	defaultStoreLocation?: LocationDto;
	setDefaultStoreLocation: (location?: LocationDto) => void;
}

/**
 * Custom hook to load default store location from services/UserData (getUserData) promise and to handle default
 * store location changes on the client by listening to browser events
 */
export function useDefaultStoreLocationLoader(): UseDefaultStoreLocationResult {
	const [isLoading, setIsLoading] = useState(false);
	const [defaultStoreLocation, setDefaultStoreLocation] = useState<LocationDto | undefined>(undefined);

	// define event handler for store location changes that will be handling state updates anytime it is changed by any other component
	useRocEventHandler(
		'roc-default-store-location-changed',
		({ location }) => {
			setDefaultStoreLocation(location);
		},
		[setDefaultStoreLocation],
	);

	// when the component mounts, begin loading the current user's default store location.
	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const userDataResponse = (await getUserData()).data;
				if (userDataResponse.defaultStoreLocation) {
					setDefaultStoreLocation(userDataResponse.defaultStoreLocation);
				}
			} catch (error) {
				if (Axios.isCancel(error)) {
					return;
				}
				const axiosError = error as AxiosError;
				console.error(`An error occurred while loading user data: ${axiosError.message}`);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	return {
		defaultStoreLocation,
		setDefaultStoreLocation,
		isLoading,
	};
}
