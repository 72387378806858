/**
 * Extends Window with recaptcha callback fields
 */
interface WindowWithReCaptchaCallbacks extends Window {
	/**
	 * Executes the methods in the rocReCaptchaCallbacks array
	 */
	onReCaptchaLoadCallback: () => void;
	/**
	 * List of recaptcha callbacks
	 */
	rocReCaptchaCallbacks: Array<() => void>;
}

export let reCaptchaCallbackPromise: Promise<void> | null = null;

/**
 * Setup function for recaptcha.
 * The window.onReCaptchaLoadCallback() gets called when the recaptcha script is loaded.
 * Then this method calls the recaptcha instances init functions, stored in window.rocReCaptchaCallbacks
 *
 * @export
 */
export default function setupReCaptcha() {
	const windowWithReCaptchaCallbacks = (window as unknown) as WindowWithReCaptchaCallbacks;

	reCaptchaCallbackPromise = new Promise((resolve) => {
		windowWithReCaptchaCallbacks.onReCaptchaLoadCallback = resolve;
	});
}
